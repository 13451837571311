import { resolvable }           from '@mathquis/modelx-resolvables';
import EnterpriseModel          from 'Models/directory/EnterpriseModel';
import PartitionModel           from 'Models/partition/PartitionModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Blamable }             from 'helpers/traits';
import { Timestampable }        from 'helpers/traits';
import { computed }             from 'mobx';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Entreprise', false)
@model.urnResource('enterprise_partition')
@doc.path('/enterprise_partitions/{?id}')
export default class EnterprisePartitionModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'clientPartitions.clients.subPartitionUrn': string;
		'contacts': id;
		'enterprise': id;
		'enterprise.enterpriseGroup': id;
		'enterprise.enterpriseType': id;
		'partitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'enterprise.commercialName': string;
		'enterprise.name': string;
		'enterprise.siren': string;
		'id': string;
	}> = {};

	static get path(): string {
		return '/enterprise_partitions/{?id}';
	}

	@computed
	public get enterpriseUrn(): string {
		return this.get('enterprise.@urn', '');
	}

	@computed
	public get partitionUrn(): string {
		return this.get('partitionUrn', '');
	}

	@computed
	public get enterpriseId(): id {
		return this.get('enterprise.id', '');
	}

	@computed
	public get enterpriseIri(): string {
		return this.get('enterprise.@id', '');
	}

	@resolvable(EnterpriseModel, { attributeName: 'enterprise' })
	declare enterprise: EnterpriseModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	declare partition: PartitionModel;
}
