import { resolvable }           from '@mathquis/modelx-resolvables';
import ChannelNotificationModel from 'Models/invoice/ChannelNotificationModel';
import InvoicingGroupModel      from 'Models/invoice/InvoicingGroupModel';
import { urn }                  from 'helpers/ModelDictionary';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import InvoicePrivateApiModel   from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoicing_group_channel_notification')
export default class InvoicingGroupChannelNotificationModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/invoicing_group_channel_notifications/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'invoicingGroup': id;
		'invoicingGroup.invoicingOwner.enterpriseUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public get invoicingGroupId() {
		return this.get('invoicingGroup.id', '');
	}

	public get invoicingGroupIri() {
		return this.get('invoicingGroup.@id', '');
	}

	public get channelNotificationIri() {
		return this.get('channelNotification.@id', '');
	}

	@resolvable(ChannelNotificationModel, { attributeName: 'channelNotification' })
	declare channelNotification: ChannelNotificationModel;

	@resolvable(InvoicingGroupModel, { attributeName: 'invoicingGroup' })
	declare invoicingGroup: InvoicingGroupModel;
}