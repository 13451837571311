import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('violation_ticket_status')
@doc.path('/violation_ticket_statuses/{?id}')
export default class ViolationTicketStatusModel extends VehiclePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': VehicleViolationTicketStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: VehicleViolationTicketStatusReference;

	public get color() {
		switch (this.reference) {
			case 'recorded':
				return 'lightblue';
			case 'transmitted':
				return 'blue';
			case 'denounced':
				return 'red';
			case 'contested':
				return 'red';
			case 'paid':
				return 'green';
			case 'cancelled':
				return 'black';
			case 'to_support':
				return 'blue';
		}
	}
}