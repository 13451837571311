import lazyWithRetry from 'tools/lazyWithRetry';

const StaffListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "staffListPage" */
	'../../pages/Staff/StaffListPage'));

const StaffDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "staffDashboard" */
	'../../pages/Staff/StaffDashboardPage'));

export default [
	{
		component: StaffListPage,
		exact: true,
		path: '/staff',
	},
	{
		component: StaffDashboardPage,
		path: '/staff/:staffId',
	},
];