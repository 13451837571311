import { urn }                from 'helpers/ModelDictionary';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoice_status')
export default class InvoiceStatusModel extends InvoicePrivateApiModel {
	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
	}> = {};

	static get path(): string {
		return '/invoice_statuses/{?id}';
	}

	public static cacheDuration = 3600;

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get reference(): InvoiceInvoiceStatusReference {
		return this.get('reference', '');
	}
}
