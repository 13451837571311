import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Statut du devis')
@model.urnResource('quotation_status')
@doc.path('/quotation_statuses/{?id}')
export default class QuotationStatusModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SalesQuotationStatusReference;
	}> = {};

	public static cacheDuration = 3600;

	@doc.string declare label: string;
	@doc.string declare reference: SalesQuotationStatusReference;

	public get color() {
		switch (this.reference) {
			case 'being_created':
			case 'canceled':
			case 'in_validation':
			case 'validated':
			case 'sent_to_client':
			case 'validated_by_client':
			case 'pre_activated':
			case 'closed':
				return 'grey';
			case 'suspended':
				return 'red';
			case 'activated':
				return 'green';
		}

		return '';
	}
}