import * as Sentry                     from '@sentry/browser';
import { COLLAPSED_BOXES_STORAGE_KEY } from 'components/DashboardBox/DashboardBox';
import { HIDDEN_COLUMNS_STORAGE_KEY }  from 'components/ListCollection';
import FilterManager                   from 'helpers/FilterManager';
import ModelCache                      from 'helpers/ModelCache';
import { reaction }                    from 'mobx';
import ActionGroupDashboardStore       from 'stores/ActionGroupDashboardStore';
import CompanyDashboardStore           from 'stores/CompanyDashboardStore';
import EnterpriseDashboardStore        from 'stores/EnterpriseDashboardStore';
import LayoutSidebarStore              from 'stores/LayoutSidebarStore';
import PartitionDashboardStore         from 'stores/PartitionDashboardStore';
import RootGroupDashboardStore         from 'stores/RootGroupDashboardStore';
import StaffDashboardStore             from 'stores/StaffDashboardStore';
import notificationApiError            from 'tools/notificationApiError';
import AppStore                        from './AppStore';
import AuthenticationStore             from './AuthenticationStore';
import BillableDashboardStore          from './BillableDashboardStore';

export const actionGroupDashboardStore = new ActionGroupDashboardStore();
export const appStore = new AppStore();
export const authenticationStore = new AuthenticationStore();
export const billableDashboardStore = new BillableDashboardStore();
export const companyDashboardStore = new CompanyDashboardStore();
export const enterpriseDashboardStore = new EnterpriseDashboardStore();
export const layoutSidebarStore = new LayoutSidebarStore();
export const partitionDashboardStore = new PartitionDashboardStore();
export const rootGroupDashboardStore = new RootGroupDashboardStore();
export const staffDashboardStore = new StaffDashboardStore();

const dashboardStores = {
	actionGroupDashboardStore,
	billableDashboardStore,
	companyDashboardStore,
	enterpriseDashboardStore,
	partitionDashboardStore,
	rootGroupDashboardStore,
	staffDashboardStore,
};

const stores = {
	appStore,
	authenticationStore,
	layoutSidebarStore,
	...dashboardStores,
};

const clearStores = (s: Record<string, { clear }> = stores) =>
	Object.values(s).forEach((store) => store.clear());

const onAuthenticatedAsync = async (): Promise<void> => {
	try {
		Sentry.setUser({
			id: authenticationStore.session.ownerId.toString(),
			username: appStore.staffMember.staff.urn,
		});

		appStore.staffMember.setId(authenticationStore.session.ownerId);

		await Promise.all([
			appStore.load(),
		]);
	} catch (err) {
		notificationApiError(err);
	}
};

const onLogout = () => {
	clearStores();
	ModelCache.destroy();
	FilterManager.destroy();
	localStorage.removeItem(HIDDEN_COLUMNS_STORAGE_KEY);
	localStorage.removeItem(COLLAPSED_BOXES_STORAGE_KEY);
	Sentry.setUser({});
};

// Lorsqu'un utilisateur se connecte ou se déconnecte
reaction(
	() => authenticationStore.isAuthenticated,
	() => authenticationStore.isAuthenticated ? onAuthenticatedAsync() : onLogout(),
);

// Initialisation au premier chargement
Promise.all([authenticationStore.session.fetch()])
	.catch(() => null)
	.then(() => authenticationStore.onLoginSuccess())
	.catch(() => null)
	.finally(() => authenticationStore.setIsReady(true));

// Reinitialisation des stores de dashboard lorsque l'url change
let lastUrl = location.href;
new MutationObserver(() => {
	const url = location.href;
	if (url !== lastUrl) {
		lastUrl = url;
		clearStores(dashboardStores);
	}
}).observe(document, { childList: true, subtree: true });

export default stores;
