import ComplianceReferentialTypeModel from 'Models/intervention/ComplianceReferentialTypeModel';
import doc                            from 'decorators/doc';
import model                          from 'decorators/model';
import { Timestampable }              from 'helpers/traits';
import { Blamable }                   from 'helpers/traits';
import InterventionPrivateApiModel    from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('Référentiel de conformité')
@model.urnResource('compliance_referential')
@doc.path('/compliance_referentials/{?id}')
export default class ComplianceReferentialModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'complianceReferentialParticleClasses.particleClass': id;
		'complianceReferentialSamplingStrategies.samplingStrategy': id;
		'complianceReferentialType': id;
		'installationTypeComplianceReferentials.installationType': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.model(ComplianceReferentialTypeModel) declare complianceReferentialType: ComplianceReferentialTypeModel;
	@doc.string declare description: string;
	@doc.string declare label: string;
}
