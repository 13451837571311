import { resolvable }        from '@mathquis/modelx-resolvables';
import ActionGroupModel      from 'Models/rights/ActionGroupModel';
import RightModel            from 'Models/rights/RightModel';
import { urn }               from 'helpers/ModelDictionary';
import { Blamable }          from 'helpers/traits';
import { Timestampable }     from 'helpers/traits';
import RightsPrivateApiModel from '../../abstracts/RightsPrivateApiModel';

@urn('$', 'rights', 'right_action_group')
export default class RightActionGroupModel extends Blamable(Timestampable(RightsPrivateApiModel)) {

	static get path(): string {
		return '/right_action_groups/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'actionGroup': id;
		'right': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'actionGroup.label': string;
		'id': string;
	}> = {};

	@resolvable(ActionGroupModel, { attributeName: 'actionGroup' })
	declare actionGroup: ActionGroupModel;

	@resolvable(RightModel, { attributeName: 'right' })
	declare right: RightModel;
}