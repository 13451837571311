import PartitionModel           from 'Models/partition/PartitionModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import { computed }             from 'mobx';
import PartitionPrivateApiModel from 'modelx/models/abstracts/PartitionPrivateApiModel';
import { getCountryName }       from 'tools/Countries';

@model.staticLabel('Adresse', false)
@model.urnResource('partition_address')
@doc.path('/partition_addresses/{?id}')
export default class PartitionAddressModel extends Blamable(Timestampable(PartitionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'partition': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare address: string;
	@doc.string declare addressBis: string;
	@doc.string declare city: string;
	@doc.string declare country: string;
	@doc.model(PartitionModel) declare partition: PartitionModel;
	@doc.string declare zipCode: string;

	public get countryName() {
		return getCountryName(this.country);
	}

	@computed
	public get fullAddress() {
		return [this.address, this.addressBis, this.zipCode, this.city, this.countryName].filter(v => v).join(' ');
	}
}
