import FileStatusModel     from 'Models/file/FileStatusModel';
import MimeTypeModel       from 'Models/file/MimeTypeModel';
import TypeSourceModel     from 'Models/file/TypeSourceModel';
import doc                 from 'decorators/doc';
import model               from 'decorators/model';
import { Timestampable }   from 'helpers/traits';
import { Blamable }        from 'helpers/traits';
import ConfigProxy         from 'tools/ConfigProxy';
import downloadUrl         from 'tools/downloadUrl';
import FilePrivateApiModel from '../../abstracts/FilePrivateApiModel';

@model.staticLabel('Fichier')
@model.urnResource('file')
@doc.path('/stonecode_file_files/{?id}')
export default class FileModel extends Blamable(Timestampable(FilePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdBy': id;
		'fileStatus': id;
		'fileStatus.reference': FileFileStatusReference;
		'typeSource': id;
		'typeSource.source': id;
		'typeSource.source.entityUrn': string;
		'typeSource.type': id;
		'typeSource.type.reference': FileTypeReference;
		'typeSource.type.service': id;
		'typeSource.type.service.serviceUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.date declare date: Moment;
	@doc.string declare file: string;
	@doc.model(FileStatusModel) declare fileStatus: FileStatusModel;
	@doc.model(MimeTypeModel) declare mimeType: MimeTypeModel;
	@doc.string declare name: string;
	@doc.number declare size: number;
	@doc.model(TypeSourceModel) declare typeSource: TypeSourceModel;
	@doc.string declare description: string;
	@doc.string declare label: string;

	public async download(name?: string) {
		await downloadUrl(this.downloadPath, name || this.name);
	}

	public get downloadPath() {
		return (this.isLoaded && !!this.id) ?
			`${ConfigProxy.getServiceConfig('file', 'api_endpoint')}${this.path}/_download` : '';
	}

	public get isImage() {
		return this.mimeType.reference.startsWith('image/');
	}
}
