import SourceModel                from 'Models/invoice/SourceModel';
import MaintenanceDutyStatusModel from 'Models/vehicle/MaintenanceDutyStatusModel';
import MaintenanceModel           from 'Models/vehicle/MaintenanceModel';
import MaintenanceTypeModel       from 'Models/vehicle/MaintenanceTypeModel';
import VehicleModel               from 'Models/vehicle/VehicleModel';
import doc                        from 'decorators/doc';
import model                      from 'decorators/model';
import nestedResolvable           from 'decorators/nestedResolvable';
import reverseResolvable          from 'decorators/reverseResolvable';
import { Timestampable }          from 'helpers/traits';
import { Blamable }               from 'helpers/traits';
import VehiclePrivateApiModel     from 'modelx/models/abstracts/VehiclePrivateApiModel';

@model.staticLabel(`Entretien`)
@model.urnResource('maintenance_duty')
@doc.path('/maintenance_duties/{?id}')
export default class MaintenanceDutyModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'fromDate[after]': string | Date;
		'fromDate[before]': string | Date;
		'fromDate[strictly_after]': string | Date;
		'fromDate[strictly_before]': string | Date;
		'maintenanceDutyStatus': id;
		'maintenanceDutyStatus.reference': VehicleMaintenanceDutyStatusReference;
		'maintenanceType': id;
		'maintenanceType.reference': VehicleMaintenanceTypeReference;
		'maintenances.supplierUrn': Urn;
		'vehicle': id;
		'vehicle.vehicleSubPartitions.ownerSubPartition.subPartitionUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'fromDate': string;
		'id': string;
	}> = {};

	@doc.date declare fromDate: Moment;
	@doc.model(MaintenanceDutyStatusModel) declare maintenanceDutyStatus: MaintenanceDutyStatusModel;
	@doc.model(MaintenanceTypeModel) declare maintenanceType: MaintenanceTypeModel;
	@doc.modelUrn(SourceModel) declare source: SourceModel;
	@doc.model(VehicleModel) declare vehicle: VehicleModel;

	@nestedResolvable()
	public get currentSubPartition() {
		return this.vehicle.currentSubPartition;
	}

	@reverseResolvable<MaintenanceModel, MaintenanceDutyModel>({
		filters: (models) => ({ 'maintenanceDuty': models.map(m => m.id) }),
		singleRequest: false,
		urn: '$:vehicle:maintenance',
	})
	public declare firstMaintenance: MaintenanceModel;
}
