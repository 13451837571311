import { resolvable }         from '@mathquis/modelx-resolvables';
import PartitionModel         from 'Models/partition/PartitionModel';
import SubPartitionModel      from 'Models/partition/SubPartitionModel';
import { urn }                from 'helpers/ModelDictionary';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import { computed }           from 'mobx';
import { getIdFromUrn }       from 'tools/UrnTools';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'owner_sub_partition')
export default class OwnerSubPartitionModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/owner_sub_partitions/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'partitionUrn': string;
		'subPartitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@computed
	public get partitionUrn(): string {
		return this.get('partitionUrn');
	}

	@computed
	public get partitionId(): id {
		return getIdFromUrn(this.partitionUrn);
	}

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	declare partition: PartitionModel;

	@computed
	public get subPartitionUrn(): string {
		return this.get('subPartitionUrn');
	}

	@computed
	public get subPartitionId(): id {
		return getIdFromUrn(this.subPartitionUrn);
	}

	@resolvable(SubPartitionModel, { attributeName: 'subPartitionUrn', cache: true })
	declare subPartition: SubPartitionModel;
}
