import SourceModel             from 'Models/invoice/SourceModel';
import AllocationRequestModel  from 'Models/supplier/AllocationRequestModel';
import OrderGroupModel         from 'Models/supplier/OrderGroupModel';
import OrderPartStatusModel    from 'Models/supplier/OrderPartStatusModel';
import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import SupplierPrivateApiModel from 'modelx/models/abstracts/SupplierPrivateApiModel';

@model.staticLabel('Commande', false)
@model.urnResource('order_part')
@doc.path('/order_parts/{?id}')
export default class OrderPartModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'createdBy': id;
		'orderGroup': id;
		'orderGroup.hasInvoice': '0' | '1';
		'orderGroup.number': number;
		'orderGroup.orderGroupStatus': id;
		'orderGroup.orderGroupStatus.reference': SupplierOrderGroupStatusReference;
		'orderGroup.orderType': id;
		'orderGroup.orderType.reference': SupplierOrderTypeReference;
		'orderGroup.reference': SupplierOrderGroupReference;
		'orderGroup.sourceUrn': string;
		'orderGroup.supplierEnterprisePurchaseCondition.purchaseCondition': id;
		'orderGroup.supplierSubPartition': id;
		'orderGroup.supplierSubPartition.ownerSubPartition.ownerPartition.partitionUrn': Urn;
		'orderGroup.supplierSubPartition.ownerSubPartition.subPartitionUrn': Urn;
		'orderGroup.supplierSubPartition.supplierCompany': id;
		'orderGroup.supplierSubPartition.supplierCompany.companyUrn': Urn;
		'orderPartStatus': id;
		'orderPartStatus.reference': SupplierOrderPartStatusReference;
		'orderPartStatusHistories.createdAt[after]': string;
		'orderPartStatusHistories.createdAt[before]': string;
		'orderPartStatusHistories.createdAt[strictly_after]': string;
		'orderPartStatusHistories.createdAt[strictly_before]': string;
		'orderPartStatusHistories.orderPartStatus': id;
		'orderPartStatusHistories.orderPartStatus.reference': SupplierOrderPartStatusReference;
		'partNumber': number;
		'periods': id;
		'periods.period': id;
		'reference': SupplierOrderPartReference;
		'sourceUrn': Urn;
		'totalExclTax[between]': string;
		'totalExclTax[gt]': string;
		'totalExclTax[gte]': string;
		'totalExclTax[lt]': string;
		'totalExclTax[lte]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'orderPartStatusHistories.createdAt': string;
		'partNumber': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	@doc.model(OrderGroupModel) declare orderGroup: OrderGroupModel;
	@doc.model(OrderPartStatusModel) declare orderPartStatus: OrderPartStatusModel;
	@doc.number declare partNumber: number;
	@doc.string declare reference: SupplierOrderPartReference;
	@doc.modelUrn(SourceModel) declare source: SourceModel;
	@doc.number declare temporaryTotalExclTax: number;
	@doc.number declare totalExclTax: number;

	@model.reverseResolvable<AllocationRequestModel>({
		singleRequest: false,
		sortWay: 'desc',
		urn: '$:supplier:allocation_request',
	})
	public declare lastAllocationRequest: AllocationRequestModel;

	@model.nestedResolvable()
	public get lastAllocationRequestStatus() {
		return this.lastAllocationRequest.allocationRequestStatus;
	}
}
// TODO DOC - Ajouter la propriété "source"
// TODO DOC - Ajouter la propriété "validator"
