import { resolvable }           from '@mathquis/modelx-resolvables';
import CompanyModel             from 'Models/directory/CompanyModel';
import { urn }                  from 'helpers/ModelDictionary';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import { computed }             from 'mobx';
import { getCountryName }       from 'tools/Countries';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@urn('$', 'directory', 'company_address')
export default class CompanyAddressModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {

	static get path(): string {
		return '/company_addresses/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'company': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	public get address(): string {
		return this.get('address', '');
	}

	public get addressBis(): string {
		return this.get('addressBis', '');
	}

	public get city(): string {
		return this.get('city', '');
	}

	public get country(): string {
		return this.get('country', '');
	}

	public get zipCode(): string {
		return this.get('zipCode', '');
	}

	public get companyId(): id {
		return this.get('company.id', '');
	}

	@computed
	public get countryName(): string {
		return getCountryName(this.country);
	}

	@computed
	public get fullAddress() {
		return [this.address, this.zipCode, this.city, this.countryName].filter(s => !!s).join(' ');
	}

	@resolvable(CompanyModel, { attributeName: 'company' })
	declare company: CompanyModel;
}