import BillingGroupModel      from 'Models/invoice/BillingGroupModel';
import BillingPeriodModel     from 'Models/invoice/BillingPeriodModel';
import CurrencyModel          from 'Models/invoice/CurrencyModel';
import InvoiceStatusModel     from 'Models/invoice/InvoiceStatusModel';
import InvoiceTypeModel       from 'Models/invoice/InvoiceTypeModel';
import PaymentModeTypeModel   from 'Models/invoice/PaymentModeTypeModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import nestedResolvable       from 'decorators/nestedResolvable';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';
import moment                 from 'moment';

@model.staticLabel('Facture', false)
@model.urnResource('invoice')
@doc.path('/invoices/{?id}')
export default class InvoiceModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'amountExclTax[between]': string;
		'amountExclTax[gt]': string;
		'amountExclTax[gte]': string;
		'amountExclTax[lt]': string;
		'amountExclTax[lte]': string;
		'amountInclTax[between]': string;
		'amountInclTax[gt]': string;
		'amountInclTax[gte]': string;
		'amountInclTax[lt]': string;
		'amountInclTax[lte]': string;
		'amountPaid[between]': string;
		'amountPaid[gt]': string;
		'amountPaid[gte]': string;
		'amountPaid[lt]': string;
		'amountPaid[lte]': string;
		'billingGroup.billingFrequency': id;
		'billingGroup.billingFrequency.reference': InvoiceBillingFrequencyReference;
		'billingGroup.billingGroupMetas.reference': InvoiceBillingGroupMetaReference;
		'billingGroup.billingGroupMetas.value': string;
		'billingGroup.billingOwner.billingOwnerBillingOwnerTags': id;
		'billingGroup.billingOwner.billingOwnerBillingOwnerTags.billingOwnerTag': id;
		'billingGroup.billingOwner.billingOwnerBillingOwnerTags.billingOwnerTag.reference': InvoiceBillingOwnerTagReference;
		'billingGroup.billingOwner.clientUrn': string;
		'billingGroup.billingOwner.companyUrn': string;
		'billingGroup.billingOwner.invoicingOwner.enterpriseGroupUrn': Urn;
		'billingGroup.billingOwner.invoicingOwner.enterpriseUrn': string;
		'billingGroup.billingOwner.ownerSubPartition.partitionUrn': string;
		'billingGroup.billingOwner.ownerSubPartition.subPartitionUrn': string;
		'billingGroup.invoicingGroup.invoicingGroupChannelNotifications.channelNotification': id;
		'billingGroup.invoicingGroup.invoicingOwner.invoicingOwnerMetas.reference': InvoiceInvoicingOwnerMetaReference;
		'billingGroup.invoicingGroup.invoicingOwner.invoicingOwnerMetas.value': id;
		'billingPeriod': id;
		'billingPeriod.partitionUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'date[after]': string;
		'date[before]': string;
		'date[strictly_after]': string;
		'date[strictly_before]': string;
		'dueDate[after]': string;
		'dueDate[before]': string;
		'dueDate[strictly_after]': string;
		'dueDate[strictly_before]': string;
		'exists[invoiceItemGroups.sourceUrn]': boolean;
		'invoiceInvoiceTags': id;
		'invoiceInvoiceTags.invoiceTag': id;
		'invoiceInvoiceTags.invoiceTag.reference': InvoiceInvoiceTagReference;
		'invoiceItemGroups.invoiceItems.invoiceItemMetas': id;
		'invoiceItemGroups.invoiceItems.invoiceItemMetas.reference': InvoiceInvoiceItemMetaReference;
		'invoiceItemGroups.invoiceItems.invoiceItemMetas.value': id;
		'invoiceItemGroups.invoiceItems.locationUrn': string;
		'invoiceItemGroups.invoiceItems.sourceUrn': string;
		'invoiceItemGroups.sourceUrn': string;
		'invoiceMetas.reference': InvoiceInvoiceMetaReference;
		'invoiceRecoveries.forwardedToLawyer': boolean;
		'invoiceStatus': id;
		'invoiceStatus.reference': InvoiceInvoiceStatusReference;
		'invoiceType': id;
		'invoiceType.reference': InvoiceInvoiceTypeReference;
		'number[between]': string;
		'number[gt]': string;
		'number[gte]': string;
		'number[lt]': string;
		'number[lte]': string;
		'reference': InvoiceInvoiceReference;
		'refundedByBillables.billable': id;
		'refundedByInvoices.refund': id;
		'refundedInvoices.invoiceRefunded': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'amountExclTax': string;
		'amountInclTax': string;
		'billingPeriod.startDate': string;
		'date': string;
		'dueDate': string;
		'id': string;
		'invoiceStatus.reference': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare address: string;
	@doc.string declare addressBis: string;
	@doc.number declare amountExclTax: number;
	@doc.number declare amountInclTax: number;
	@doc.number declare amountPaid:number;
	@doc.number declare amountVat: number;
	@doc.model(BillingGroupModel) declare billingGroup: BillingGroupModel;
	@doc.model(BillingPeriodModel) declare billingPeriod: BillingPeriodModel;
	@doc.string declare city: string;
	@doc.string declare country: string;
	@doc.model(CurrencyModel) declare currency: CurrencyModel;
	@doc.date declare date: Moment;
	@doc.string declare denomination: string;
	@doc.string declare denominationBis: string;
	@doc.date declare dueDate: Moment;
	@doc.string declare freeReference: string;
	@doc.model(InvoiceStatusModel) declare invoiceStatus: InvoiceStatusModel;
	@doc.model(InvoiceTypeModel) declare invoiceType: InvoiceTypeModel;
	@doc.string declare note: string;
	@doc.number declare number: number;
	@doc.model(PaymentModeTypeModel) declare paymentModeType: PaymentModeTypeModel;
	@doc.string declare reference: InvoiceInvoiceReference;
	@doc.string declare zipCode: string;

	@nestedResolvable()
	public get subPartition() {
		return this.billingGroup.billingOwner.ownerSubPartition.subPartition;
	}

	@nestedResolvable()
	public get billingOwner() {
		return this.billingGroup.billingOwner;
	}

	@nestedResolvable()
	public get enterprisePartition() {
		return this.billingGroup.billingOwner.enterprisePartition;
	}

	@nestedResolvable()
	public get enterprise() {
		return this.billingGroup.billingOwner.enterprisePartition.enterprise;
	}

	@nestedResolvable()
	public get company() {
		return this.billingGroup.billingOwner.company;
	}

	@nestedResolvable()
	public get invoicingGroup() {
		return this.billingGroup.invoicingGroup;
	}

	@nestedResolvable()
	public get invoicingOwner() {
		return this.billingGroup.invoicingGroup.invoicingOwner;
	}

	@computed
	public get isOverdue() {
		return moment().isAfter(this.dueDate, 'days');
	}

	@computed
	public get nbDaysOverdue() {
		const nb = moment().diff(this.dueDate, 'days');

		return (nb > 0) ? nb : 0;
	}
	
	@computed
	public get remainingAmount() {
		return this.amountInclTax - this.amountPaid;
	}
}
