import lazyWithRetry from 'tools/lazyWithRetry';

const PaymentModeRuleListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "nafCodeListPage" */
	'../../pages/PaymentModeRuleCode/PaymentModeRuleListPage'));

export default [
	{
		component: PaymentModeRuleListPage,
		exact: true,
		path: '/payment_mode_rules',
	},
];