import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import RegistryServiceModel        from 'modelx/models/private/registry/ServiceModel';
import NotificationPrivateApiModel from '../../abstracts/NotificationPrivateApiModel';

@model.staticLabel('Service')
@model.urnResource('service')
@doc.path('/stonecode_notification_services/{?id}')
export default class ServiceModel extends Timestampable(NotificationPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'channels': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'serviceUrn': string;
	}> = {};


	@doc.model(RegistryServiceModel) declare service: RegistryServiceModel;
}
