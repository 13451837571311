import { ApiCollection }   from 'Collections/ApiCollection';
import ActionGroupModel    from 'Models/rights/ActionGroupModel';
import RightModel          from 'Models/rights/RightModel';
import AbstractModelXStore from 'stores/AbstractModelXStore';

export default class ActionGroupDashboardStore extends AbstractModelXStore {

	public actionGroup = new ActionGroupModel();
	public collectionToPublishRight = new ApiCollection(RightModel);

	public async initAsync(actionGroupId: id) {
		this.clear();

		await Promise.all([
			this.actionGroup.set({ id: actionGroupId }).fetch(),
			this.reloadToPublishRights(actionGroupId),
		]);
	}

	public reloadToPublishRights(id: id = this.actionGroup.id) {
		return this.collectionToPublishRight.setFilter('toPublish', true).listBy([id], 'rightActionGroups.actionGroup');
	}
}