import lazyWithRetry from 'tools/lazyWithRetry';

const GlobalContactListPage = lazyWithRetry(() => import(
	'../../../pages/directory/GlobalContact/GlobalContactListPage'));

const GlobalContactDashboardPage = lazyWithRetry(() => import(
	'../../../pages/directory/GlobalContact/GlobalContactDashboardPage'));

export default [
	{
		component: GlobalContactListPage,
		exact: true,
		path: '/global_contacts',
	},
	{
		component: GlobalContactDashboardPage,
		exact: true,
		path: '/global_contacts/:contactId',
	},
];
