import { resolvable }         from '@mathquis/modelx-resolvables';
import BillingGroupModel      from 'Models/invoice/BillingGroupModel';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import { getIdFromUrn }       from 'tools/UrnTools';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type BillingGroupMetaReference =
	'contract_urn'
	| 'contract_iteration_urn'
	| 'quotation_urn'
	| 'purchase_order_urn'
	| 'dc4'
	| 'framework_agreement_iteration_urn';

export default class BillingGroupMetaModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billingGroup': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'reference': BillingGroupMetaReference;
		'service': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
		'value': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'reference': string;
		'updatedAt': string;
		'value': string;
	}> = {};

	static get path(): string {
		return '/billing_group_metas/{?id}';
	}

	@computed
	public get billingGroupId(): id {
		return this.get('billingGroup.id');
	}

	@computed
	public get reference(): BillingGroupMetaReference {
		return this.get('reference');
	}

	@computed
	public get value(): string {
		return this.get('value');
	}

	@computed
	public get valueId(): id {
		return getIdFromUrn(this.value);
	}

	@resolvable(BillingGroupModel, { attributeName: 'billingGroup' })
	declare billingGroup: BillingGroupModel;

	public get service(): string {
		return this.get('service', '');
	}
}
