import { ApiCollection }                      from 'Collections/ApiCollection';
import EnterpriseModel                        from 'Models/directory/EnterpriseModel';
import FileModel                              from 'Models/file/FileModel';
import SourceModel                            from 'Models/file/SourceModel';
import TypeSourceModel                        from 'Models/file/TypeSourceModel';
import DebitMandateModel                      from 'Models/invoice/DebitMandateModel';
import DebitMandateStatusHistoryModel         from 'Models/invoice/DebitMandateStatusHistoryModel';
import DebitMandateStatusModel                from 'Models/invoice/DebitMandateStatusModel';
import InvoicingGroupChannelNotificationModel from 'Models/invoice/InvoicingGroupChannelNotificationModel';
import InvoicingGroupEmailModel               from 'Models/invoice/InvoicingGroupEmailModel';
import InvoicingGroupModel                    from 'Models/invoice/InvoicingGroupModel';
import InvoicingOwnerModel                    from 'Models/invoice/InvoicingOwnerModel';
import EnterpriseConfigModel                  from 'Models/sales/EnterpriseConfigModel';
import AbstractModelXStore                    from 'stores/AbstractModelXStore';

export default class EnterpriseDashboardStore extends AbstractModelXStore {

	public debitMandateCollection = new ApiCollection(DebitMandateModel);

	public debitMandateMandateFileCollection = new ApiCollection(FileModel);
	public debitMandateRibFileCollection = new ApiCollection(FileModel);
	public debitMandateSourceCollection = new ApiCollection(SourceModel);
	public debitMandateStatusCollection = new ApiCollection(DebitMandateStatusModel);
	public debitMandateStatusHistoryCollection = new ApiCollection(DebitMandateStatusHistoryModel);
	public debitMandateTypeSourceCollection = new ApiCollection(TypeSourceModel);

	public enterprise = new EnterpriseModel();
	public enterpriseConfig = new EnterpriseConfigModel();
	public invoicingGroupChannelNotificationCollection = new ApiCollection(InvoicingGroupChannelNotificationModel);
	public invoicingGroupCollection = new ApiCollection(InvoicingGroupModel);
	public invoicingGroupEmailCollection = new ApiCollection(InvoicingGroupEmailModel);

	public invoicingOwner = new InvoicingOwnerModel();

	public fetchDebitMandatesAsync = async () => {
		await this.debitMandateCollection
			.setSorts({
				'partitionUrn': true,
				// eslint-disable-next-line sort-keys
				'invoicingGroup.id': true,
				// eslint-disable-next-line sort-keys
				'debitMandateStatus.position': true,
			})
			.setFilter('invoicingGroup.invoicingOwner.enterpriseUrn', this.enterprise.urn)
			.list();

		await Promise.all([
			this.debitMandateMandateFileCollection
				.setFilters({
					'typeSource.type.reference': 'debit_mandate',
				})
				.listBy(this.debitMandateCollection.urns, 'typeSource.source.entityUrn'),

			this.debitMandateRibFileCollection
				.setFilters({
					'typeSource.type.reference': 'debit_mandate_rib',
				})
				.listBy(this.debitMandateCollection.urns, 'typeSource.source.entityUrn'),

			this.debitMandateTypeSourceCollection
				.listBy(this.debitMandateCollection.urns, 'source.entityUrn'),

			this.debitMandateSourceCollection
				.listBy(this.debitMandateCollection.urns, 'entityUrn'),

			this.debitMandateStatusCollection
				.list({ cache: 3600, }),

			this.debitMandateStatusHistoryCollection
				.listByFromCollection(this.debitMandateCollection, 'id', 'debitMandate'),
		]);
	};

	public fetchInvoicingGroupAsync = async () => {
		await this.invoicingGroupCollection
			.setFilter('invoicingOwner.enterpriseUrn', this.enterprise.urn)
			.setSort('position')
			.list();

		await this.invoicingGroupEmailCollection
			.listByFromCollection(this.invoicingGroupCollection, 'id', 'invoicingGroup');
	};

	public getDebitMandateMandateFiles = (debitMandate: DebitMandateModel) => {
		const sources = this.debitMandateSourceCollection.filterBy('entityUrn', debitMandate.urn);
		const typeSourceIds = this.debitMandateTypeSourceCollection
			.filterBy('sourceId', sources.map(s => s.id))
			.map(ts => ts.id);
		return this.debitMandateMandateFileCollection.filter(f => typeSourceIds.includes(f.getId('typeSource')));
	};

	public getDebitMandateRibFiles = (debitMandate: DebitMandateModel) => {
		const sources = this.debitMandateSourceCollection.filterBy('entityUrn', debitMandate.urn);
		const typeSourceIds = this.debitMandateTypeSourceCollection
			.filterBy('sourceId', sources.map(s => s.id))
			.map(ts => ts.id);
		return this.debitMandateRibFileCollection.filter(f => typeSourceIds.includes(f.getId('typeSource')));
	};

	public initAsync = async (enterpriseId: id) => {
		await Promise.all([
			this.enterprise.set({ id: enterpriseId }).fetch().then(async () => {
				await Promise.all([
					new ApiCollection(EnterpriseConfigModel)
						.setFilters({ enterpriseUrn: this.enterprise.urn })
						.list()
						.then(coll => this.enterpriseConfig.set(coll.first()?.attributes)),

					new ApiCollection(InvoicingOwnerModel)
						.setFilters({ enterpriseUrn: this.enterprise.urn })
						.list()
						.then(coll => this.invoicingOwner.set(coll.first()?.attributes)),

					this.fetchDebitMandatesAsync(),

					this.fetchInvoicingGroupAsync(),

					this.invoicingGroupChannelNotificationCollection
						.setFilter('invoicingGroup.invoicingOwner.enterpriseUrn', this.enterprise.urn)
						.list(),
				]);
			}),
		]);
	};
}
