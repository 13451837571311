import { ApiCollection }                from 'Collections/ApiCollection';
import StaffAddressModel                from 'Models/rh/StaffAddressModel';
import StaffMemberContractModel         from 'Models/rh/StaffMemberContractModel';
import StaffMemberModel                 from 'Models/rh/StaffMemberModel';
import StaffMemberRootGroupModel        from 'Models/rh/StaffMemberRootGroupModel';
import StaffModel                       from 'Models/rh/StaffModel';
import SalesValidationThresholdModel    from 'Models/sales/ValidationThresholdModel';
import SupplierValidationThresholdModel from 'Models/supplier/ValidationThresholdModel';
import AbstractModelXStore              from 'stores/AbstractModelXStore';

export default class StaffDashboardStore extends AbstractModelXStore {

	public collectionSalesValidationThreshold = new ApiCollection(SalesValidationThresholdModel);
	public collectionSupplierValidationThreshold = new ApiCollection(SupplierValidationThresholdModel);
	public staff = new StaffModel();
	public staffAddressCollection = new ApiCollection(StaffAddressModel);
	public staffMemberCollection = new ApiCollection(StaffMemberModel);
	public staffMemberContractCollection = new ApiCollection(StaffMemberContractModel);
	public staffMemberRootGroupCollection = new ApiCollection(StaffMemberRootGroupModel);

	public async initAsync(id: id) {
		await this.staff.setId(id).fetch();

		await Promise.all([
			this.staffAddressCollection.listBy([id], 'staff'),
			this.staffMemberCollection.listBy([id], 'staff').then(() => {
				return Promise.all([
					this.collectionSalesValidationThreshold
						.listBy(this.staffMemberCollection.urns as Urn[], 'staffMemberUrn'),

					this.collectionSupplierValidationThreshold
						.listBy(this.staffMemberCollection.urns as Urn[], 'staffMemberUrn'),
				]);
			}),
			this.staffMemberContractCollection.listBy([id], 'staffMember.staff'),
			this.staffMemberRootGroupCollection.listBy([id], 'staffMember.staff'),
		]);
	}
}