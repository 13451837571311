import lazyWithRetry from 'tools/lazyWithRetry';

const MeasureMethodologyListPage = lazyWithRetry(() => import(
	'../../../pages/MeasureMethodology/MeasureMethodologyListPage'));

export default [
	{
		component: MeasureMethodologyListPage,
		exact: true,
		path: '/measure_methodologies',
	},
];
