import lazyWithRetry from 'tools/lazyWithRetry';

const AccreditationSubPartitionDashboardPage = lazyWithRetry(() => import(
	'../../../pages/AccreditationSubPartition/AccreditationSubPartitionDashboardPage'));

export default [
	{
		component: AccreditationSubPartitionDashboardPage,
		exact: true,
		path: '/accreditation_sub_partitions/:accreditationSubPartitionId',
	},
];
