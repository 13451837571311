import { MenuFoldOutlined } from '@ant-design/icons';
import AntdLayout           from 'antd/lib/layout';
import Spin                 from 'antd/lib/spin';
import { observer }         from 'mobx-react';
import { renderRoutes }     from 'react-router-config';
import { Link }             from 'react-router-dom';
import React                from 'react';
import privateRoutes        from 'routes/privates';
import ConfigProxy          from 'tools/ConfigProxy';
import navigate             from 'tools/navigate';
import Breadcrumb           from './Breadcrumb';
import AppUserMenu          from './components/AppUserMenu';
import LayoutSidebar        from './components/LayoutSidebar';
import Navigation           from './components/Navigation';
import './css/AppName.scss';

@observer
export default class Layout extends React.Component<INavigationProps> {

	public state = { mainMenuCollapsed: true };

	public render() {
		const { Content, Header, Sider } = AntdLayout;
		const { mainMenuCollapsed } = this.state;

		const { location } = this.props;

		return (
			<div className={`app-container`}>
				<AntdLayout className="app-main">
					<Sider className="app-side-menu" collapsed={mainMenuCollapsed} collapsedWidth={70} width={300}>
						{!mainMenuCollapsed && <div className="side-menu-focus" onClick={this._toggleMainMenu} />}

						<div className="menu-toggle-trigger" onClick={this._toggleMainMenu}>
							<div className="app-name">{ConfigProxy.get('TITLE')}</div>

							<MenuFoldOutlined className="Layout__MenuIcon" />
						</div>

						<Navigation location={location} />
					</Sider>

					<div className="app-body">
						<div className="app-header">
							<AntdLayout className="app-header-layout">
								<Header className="app-header-div">
									<Link to={navigate.toHome()}>
										<div className="app-name">
											<div id="app-name-text">
												{ConfigProxy.get('TITLE')}
											</div>
										</div>
									</Link>
									<div className="app-right">
										<AppUserMenu />
									</div>
								</Header>
							</AntdLayout>
						</div>
						<AntdLayout className="page-content-wrapper">
							<LayoutSidebar />

							<Content className="page-content">
								<Breadcrumb {...this.props} />

								<React.Suspense fallback={<Spin className="app-centered-spin" size="large" />}>
									{renderRoutes(privateRoutes)}
								</React.Suspense>
							</Content>
						</AntdLayout>
					</div>
				</AntdLayout>
			</div>
		);
	}

	private _toggleMainMenu = () => this.setState({ mainMenuCollapsed: !this.state.mainMenuCollapsed });
}
