import lazyWithRetry from 'tools/lazyWithRetry';

const AccountingCodeListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "accountingCodeListPage" */
	'../../pages/AccountingCode/AccountingCodeListPage'));

export default [
	{
		component: AccountingCodeListPage,
		exact: true,
		path: '/accounting_codes',
	},
];