import lazyWithRetry from 'tools/lazyWithRetry';

const SubPartitionListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "subPartitionListPage" */
	'../../pages/SubPartition/SubPartitionListPage'),
);

export default [
	{
		component: SubPartitionListPage,
		exact: true,
		path: '/sub_partitions',
	},
];
