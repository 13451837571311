import { ApiCollection }     from 'Collections/ApiCollection';
import RootGroupModel        from 'Models/rh/RootGroupModel';
import RightActionGroupModel from 'Models/rights/RightActionGroupModel';
import AbstractModelXStore   from 'stores/AbstractModelXStore';

export default class RootGroupDashboardStore extends AbstractModelXStore {

	public rightActionGroupCollection = new ApiCollection(RightActionGroupModel);
	public rootGroup = new RootGroupModel();

	public async initAsync(id: id) {
		this.clear();

		this.setIsLoading(true);

		await this.rootGroup.set({ id }).fetch();

		if (this.rootGroup.right.id) {
			await this.rightActionGroupCollection
				.setSort('actionGroup.label')
				.setFilter('right', this.rootGroup.right.id)
				.listBy([this.rootGroup.right.id], 'right');
		}

		this.setIsLoading(false);
	}
}