import { resolvable }                   from '@mathquis/modelx-resolvables';
import { BillingPeriodStatusReference } from 'Models/invoice/BillingPeriodStatusModel';
import BillingPeriodStatusModel         from 'Models/invoice/BillingPeriodStatusModel';
import PartitionModel                   from 'Models/partition/PartitionModel';
import { urn }                          from 'helpers/ModelDictionary';
import { Timestampable }                from 'helpers/traits';
import { Blamable }                     from 'helpers/traits';
import { computed }                     from 'mobx';
import moment                           from 'moment';
import InvoicePrivateApiModel           from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'billing_period')
export default class BillingPeriodModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billingPeriodStatus': id;
		'billingPeriodStatus.reference': BillingPeriodStatusReference;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'partitionUrn': string;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'billingPeriodStatus.label': string;
		'endDate': string;
		'id': string;
		'invoiceNumber': string;
		'startDate': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/billing_periods/{?id}';
	}

	@resolvable(BillingPeriodStatusModel, { attributeName: 'billingPeriodStatus', cache: true })
	declare billingPeriodStatus: BillingPeriodStatusModel;

	@computed
	public get billingPeriodStatusId(): id {
		return this.get('billingPeriodStatus.id');
	}

	@computed
	public get billingPeriodStatusIri(): string {
		return this.get('billingPeriodStatus.@id');
	}

	@computed
	public get amountTaxExcl(): number {
		return this.get('amountTaxExcl');
	}

	@computed
	public get amountVat(): number {
		return this.get('amountVat');
	}

	@computed
	public get amountTaxIncl(): number {
		return this.get('amountTaxIncl');
	}

	public get invoiceNumber(): number {
		return this.get('invoiceNumber', 0);
	}

	@computed
	public get endDate(): Moment {
		return moment(this.get('endDate', moment()));
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate', moment()));
	}

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	declare partition: PartitionModel;
}
