import AllocationRequestStatusModel from 'Models/supplier/AllocationRequestStatusModel';
import OrderPartModel               from 'Models/supplier/OrderPartModel';
import doc                          from 'decorators/doc';
import model                        from 'decorators/model';
import { Timestampable }            from 'helpers/traits';
import { Blamable }                 from 'helpers/traits';
import SupplierPrivateApiModel      from '../../abstracts/SupplierPrivateApiModel';

@model.staticLabel(`Demande d'étalement`, false)
@model.urnResource('allocation_request')
@doc.path('/allocation_requests/{?id}')
export default class AllocationRequestModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'allocationRequestStatus': id;
		'allocationRequestStatus.reference': SupplierAllocationRequestStatusReference;
		'orderPart': id;
		'orderPart.orderGroup': id;
		'orderPart.orderGroup.supplierSubPartition.ownerSubPartition': id;
		'orderPart.orderGroup.supplierSubPartition.ownerSubPartition.ownerPartition': id;
		'orderPart.orderGroup.supplierSubPartition.ownerSubPartition.ownerPartition.partitionUrn': string;
		'orderPart.orderGroup.supplierSubPartition.ownerSubPartition.subPartitionUrn': string;
		'orderPart.orderGroup.supplierSubPartition.supplierCompany': id;
		'orderPart.orderGroup.supplierSubPartition.supplierCompany.companyUrn': string;
		'orderPart.reference': SupplierOrderPartReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(AllocationRequestStatusModel) declare allocationRequestStatus: AllocationRequestStatusModel;
	@doc.model(OrderPartModel) declare orderPart: OrderPartModel;
	@doc.string declare request: string;

	@model.nestedResolvable()
	public get orderGroup() {
		return this.orderPart.orderGroup;
	}

	@model.nestedResolvable()
	public get partition() {
		return this.orderPart.orderGroup.supplierSubPartition.partition;
	}

	@model.nestedResolvable()
	public get subPartition() {
		return this.orderPart.orderGroup.supplierSubPartition.subPartition;
	}

	@model.nestedResolvable()
	public get supplierCompany() {
		return this.orderPart.orderGroup.supplierSubPartition.supplierCompany;
	}
}