import InstallationCategoryModel   from 'Models/intervention/InstallationCategoryModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Type d\'installation')
@model.urnResource('installation_type')
@doc.path('/installation_types/{?id}')
export default class InstallationTypeModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'installationCategory': id;
		'installationCategory.reference': InterventionInstallationCategoryReference;
		'label': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare description: string;
	@doc.model(InstallationCategoryModel) declare installationCategory: InstallationCategoryModel;
	@doc.string declare label: string;
}