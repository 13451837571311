import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import RegistryPrivateApiModel from 'modelx/models/abstracts/RegistryPrivateApiModel';

@model.staticLabel('StonecodeRegistryService')
@model.urnResource('stonecode_registry_service')
@doc.path('/stonecode_registry_services/{?id}')
export default class ServiceModel extends Timestampable(RegistryPrivateApiModel) {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare endpoint: string;
}
