import FileModel                   from 'Models/file/FileModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import { IHazardNameProps }        from 'modelNames/intervention/HazardName';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('Risque')
@model.urnResource('hazard')
@doc.path('/hazards/{?id}')
export default class HazardModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'label': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	@doc.boolean declare archived: boolean;
	@doc.string declare label: string;

	@model.fileResolvable('hazard_pictogram')
	public declare pictogram: FileModel;

	public renderName(props?: Omit<IHazardNameProps, 'model'>) {
		return super.renderName(props);
	}
}
