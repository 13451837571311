import { resolvable }         from '@mathquis/modelx-resolvables';
import ClientModel            from 'Models/directory/ClientModel';
import ClientPartitionModel   from 'Models/directory/ClientPartitionModel';
import OwnerSubPartitionModel from 'Models/sales/OwnerSubPartitionModel';
import { urn }                from 'helpers/ModelDictionary';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import { computed }           from 'mobx';
import { getIdFromUrn }       from 'tools/UrnTools';
import SalesPrivateApiModel   from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'owner')
export default class OwnerModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'clientPartitionUrn': string;
		'clientUrn': string;
		'companyUrn': string;
		'enterprisePartitionUrn': string;
		'enterpriseUrn': string;
		'ownerSubPartition': id;
		'ownerSubPartition.partitionUrn': string;
		'ownerSubPartition.subPartitionUrn': string;
	}> = {};

	static get path(): string {
		return '/owners/{?id}';
	}

	@computed
	public get companyUrn(): string {
		return this.get('companyUrn', '');
	}

	@computed
	public get companyId(): id {
		return parseInt(getIdFromUrn(this.companyUrn));
	}

	@computed
	public get clientId(): id {
		return parseInt(getIdFromUrn(this.get('clientUrn', '')));
	}

	@computed
	public get partitionUrn(): string {
		return this.get('partitionUrn');
	}

	@computed
	public get clientUrn(): string {
		return this.get('clientUrn');
	}

	@computed
	public get enterpriseUrn(): string {
		return this.get('enterpriseUrn', '');
	}

	@computed
	public get enterprisePartitionUrn(): string {
		return this.get('enterprisePartitionUrn', '');
	}

	@computed
	public get enterpriseId(): id {
		return parseInt(getIdFromUrn(this.enterpriseUrn));
	}

	@computed
	public get enterprisePartitionId(): id {
		return parseInt(getIdFromUrn(this.enterprisePartitionUrn));
	}

	@resolvable(ClientModel, { attributeName: 'clientUrn' })
	declare client: ClientModel;

	@resolvable(ClientPartitionModel, { attributeName: 'clientPartitionUrn' })
	declare clientPartition: ClientPartitionModel;

	@resolvable(OwnerSubPartitionModel, { attributeName: 'ownerSubPartition' })
	declare ownerSubPartition: OwnerSubPartitionModel;
}
