import { HomeOutlined } from '@ant-design/icons';
import Breadcrumb       from 'antd/lib/breadcrumb';
import View             from 'components/View';
import ModelDictionary  from 'helpers/ModelDictionary';
import { observer }     from 'mobx-react';
import React            from 'react';
import routes           from 'routes';
import { getMenuItems } from './components/Navigation';

export default observer((props) => {
	const [items, setItems] = React.useState<{ title: string; }[]>([]);
	const [mounted, setMounted] = React.useState(false);

	React.useEffect(() => {
		const crumbs = routes
			.filter(({ path }) => props.match.path.includes(path))
			.map(({ path, ...rest }) => ({
				path: Object.keys(props.match.params).length
					? Object.keys(props.match.params).reduce(
						(path, param) => path.replace(
							`:${param}`, props.match.params[param] || '',
						), path,
					)
					: path,
				...rest,
			}));

		const models = ModelDictionary.models;

		const menuItems = getMenuItems();

		setItems(crumbs
			.filter(({ path }) => !['/', '/*'].includes(path))
			.map(({ path }) => {
				let name = '';

				const items = (getParent(menuItems, path) || []);

				if (items.length) {
					name = items.find(i => i.key === path)?.label;
				} else {
					const modelDashboard = models.find(m => path.match(new RegExp(m.path.replace('{?id}', '.*'))));
					if (modelDashboard) {
						name = `Fiche ${modelDashboard.staticLabel.toLowerCase()}`;
					} else {
						const modelList = models.find(m => path === m.path.replace('/{?id}', ''));

						if (modelList) {
							name = `Liste des ${modelList.pluralStaticLabel.toLowerCase()}`;
						}
					}
				}

				const mItems = items.filter(i => i.key !== path);

				return { menu: mItems.length ? { items: mItems } : undefined, title: name };
			})
			.filter(c => c.title));

	}, [props.match.path, ModelDictionary.size]);

	React.useEffect(() => setMounted(true), []);

	if (!mounted) {
		return <View height={46} paddingH={40} paddingV={8} />;
	}

	if (!items.length) {
		return null;
	}

	const displayItems = [{ href: '/', title: <HomeOutlined /> }, ...items];

	return (
		<View maxWidthF paddingH={40} paddingV={8} width="max-content">
			<View paddingV={4}>
				<Breadcrumb items={displayItems} separator=">" />
			</View>
		</View>
	);
});

const getParent = (data, wantedKey: string) => {
	for (const datum of data) {
		if (!datum) {
			return;
		}

		const { children, key } = datum;

		if (key === wantedKey) {
			return data;
		}

		const temp = children && getParent(children, wantedKey);
		if (temp) {
			return temp;
		}
	}
};
