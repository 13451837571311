import OwnerSubPartitionModel  from 'Models/supplier/OwnerSubPartitionModel';
import SupplierCompanyModel    from 'Models/supplier/SupplierCompanyModel';
import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.staticLabel('Fournisseur')
@model.urnResource('supplier_sub_partition')
@doc.path('/supplier_sub_partitions/{?id}')
export default class SupplierSubPartitionModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'ownerSubPartition': id;
		'ownerSubPartition.subPartitionUrn': string;
		'supplierCompany': id;
		'supplierCompany.companyUrn': string;
		'supplierCompany.supplierEnterprise.enterpriseUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(OwnerSubPartitionModel) declare ownerSubPartition: OwnerSubPartitionModel;
	@doc.model(SupplierCompanyModel) declare supplierCompany: SupplierCompanyModel;

	@model.nestedResolvable()
	public get partition() {
		return this.ownerSubPartition.subPartition.partition;
	}

	@model.nestedResolvable()
	public get subPartition() {
		return this.ownerSubPartition.subPartition;
	}
}