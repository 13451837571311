import ChangeStaffPasswordModel from 'Models/rh/ChangeStaffPasswordModel';
import Col                      from 'antd/lib/col';
import Row                      from 'antd/lib/row';
import AbstractForm             from 'components/AbstractForm/AbstractForm';
import Form                     from 'components/Form';
import Input                    from 'components/Input';
import { ROW_SM_GUTTER }        from 'constants/Sizes';
import React                    from 'react';
import { appStore }             from 'stores';
import { notificationSuccess }  from 'tools/notification';

export interface IStaffPasswordFormProps {
	onSuccess?: (model: ChangeStaffPasswordModel) => void;
}

export default class StaffPasswordForm extends AbstractForm<IStaffPasswordFormProps> {

	public render() {
		return (
			<Form onFinish={this._onFinish} ref={this.formRef}>
				<Row gutter={ROW_SM_GUTTER}>
					<Col>
						<Form.Item label="Nouveau mot de passe" name="plainPassword" required={true}>
							<Input placeholder="Mot de passe" type="password" />
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							label="Confirmer le mot de passe"
							name="confirmPassword"
							rules={[
								{ message: 'Veuillez confirmer le mot de passe', required: true },
								{ validator: this._checkConfirmPlainPassword },
							]}
						>
							<Input placeholder="Confirmer le mot de passe" type="password" />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		);
	}

	private _checkConfirmPlainPassword = (rule, value, callback) => {
		if (
			value &&
			value !== this.formRef.current?.getFieldValue('plainPassword')
		) {
			callback('Le mot de passe ne correspond pas');
		} else {
			callback();
		}
	};

	private _onFinish = async values => {
		const { onSuccess } = this.props;

		this.setSubmitting(false);

		try {
			const changeStaffPassword = new ChangeStaffPasswordModel();

			await changeStaffPassword.patch({
				staff: appStore.staffMember.staff.iri,
				sendMail: false,
				plainPassword: values.plainPassword,
				// partitionUrn: ConfigProxy.get('PARTITION_URN'),
			});

			if (onSuccess) {
				onSuccess(changeStaffPassword);
			}

			notificationSuccess(`Le mot de passe a bien été modifié`);

		} catch (err) {
			this._onError(err);
		} finally {
			this.setSubmitting(false);
		}
	};
}
