import { resolvable }                from '@mathquis/modelx-resolvables';
import ClientModel                   from 'Models/directory/ClientModel';
import BillingFrequencyModel         from 'Models/invoice/BillingFrequencyModel';
import { BillingGroupMetaReference } from 'Models/invoice/BillingGroupMetaModel';
import BillingOwnerModel             from 'Models/invoice/BillingOwnerModel';
import InvoicingGroupModel           from 'Models/invoice/InvoicingGroupModel';
import PaymentModeModel              from 'Models/invoice/PaymentModeModel';
import { urn }                       from 'helpers/ModelDictionary';
import { Blamable }                  from 'helpers/traits';
import { Timestampable }             from 'helpers/traits';
import { computed }                  from 'mobx';
import InvoicePrivateApiModel        from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'billing_group')
export default class BillingGroupModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billables': id;
		'billables.billableItems.billableItemMetas.reference': string;
		'billables.billableItems.billableItemMetas.value': id;
		'billables.billableItems.locationUrn': string;
		'billables.billableStatus': id;
		'billables.billableStatus.reference': string;
		'billables.invoiceableAt[after]': string;
		'billables.invoiceableAt[before]': string;
		'billables.invoiceableAt[strictly_after]': string;
		'billables.invoiceableAt[strictly_before]': string;
		'billingGroupMetas.reference': BillingGroupMetaReference;
		'billingGroupMetas.value': id;
		'billingOwner.clientUrn': string;
		'billingOwner.companyUrn': string;
		'billingOwner.invoicingOwner.enterpriseUrn': string;
		'billingOwner.ownerSubPartition': id;
		'billingOwner.ownerSubPartition.partitionUrn': string;
		'billingOwner.ownerSubPartition.subPartitionUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'invoices.billingPeriod': id;
		'invoicingGroup': id;
		'invoicingGroup.invoicingOwner.enterpriseUrn': string;
		'sourceUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/billing_groups/{?id}';
	}

	@computed
	public get invoicingGroupId(): id {
		return this.get('invoicingGroup.id');
	}

	@computed
	public get invoicingGroupIri(): string {
		return this.get('invoicingGroup.@id');
	}

	@computed
	public get name(): string {
		return this.get('name');
	}

	@computed
	public get billingDays(): number[] {
		return this.get('billingDays', []);
	}

	@computed
	public get billingFrequencyId(): id {
		return this.get('billingFrequency.id');
	}

	@computed
	public get billingFrequencyIri(): string {
		return this.get('billingFrequency.@id');
	}

	@computed
	public get sourceUrn(): string {
		return this.get('sourceUrn');
	}

	@computed
	public get freeReference(): string {
		return this.get('freeReference');
	}

	@computed
	public get paymentModeId(): id {
		return this.get('paymentMode.id');
	}

	@computed
	public get note(): string {
		return this.get('note', '');
	}

	@computed
	public get paymentModeIri(): string {
		return this.get('paymentMode.@id');
	}

	@computed
	public get billingOwnerId(): id {
		return this.get('billingOwner.id');
	}

	@resolvable(BillingFrequencyModel, { attributeName: 'billingFrequency' })
	declare billingFrequency: BillingFrequencyModel;

	@resolvable(BillingOwnerModel, { attributeName: 'billingOwner' })
	declare billingOwner: BillingOwnerModel;

	@resolvable(ClientModel, { attributeName: 'clientUrn' })
	declare client: ClientModel;

	@resolvable(InvoicingGroupModel, { attributeName: 'invoicingGroup' })
	declare invoicingGroup: InvoicingGroupModel;

	@resolvable(PaymentModeModel, { attributeName: 'paymentMode', cache: true })
	declare paymentMode: PaymentModeModel;
}
