import SubPartitionModel      from 'Models/partition/SubPartitionModel';
import OwnerSubPartitionModel from 'Models/rh/OwnerSubPartitionModel';
import RootGroupModel         from 'Models/rh/RootGroupModel';
import StaffMemberModel       from 'Models/rh/StaffMemberModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import RhPrivateApiModel      from '../../abstracts/RhPrivateApiModel';

@model.staticLabel(`Utilisateur du groupe`)
@model.urnResource('staff_member_root_group')
@doc.path('/staff_member_root_groups/{?id}')
export default class StaffMemberRootGroupModel extends Blamable(Timestampable(RhPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'ownerSubPartition': id;
		'ownerSubPartition.partitionUrn': string;
		'ownerSubPartition.subPartitionUrn': string;
		'rootGroup': id;
		'staffMember': id;
		'staffMember.staff': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'staffMember.staff.firstName': string;
		'staffMember.staff.lastName': string;
	}> = {};

	@doc.model(OwnerSubPartitionModel) declare ownerSubPartition: OwnerSubPartitionModel;
	@doc.model(RootGroupModel) declare rootGroup: RootGroupModel;
	@doc.model(StaffMemberModel) declare staffMember: StaffMemberModel;
	@doc.modelUrn(SubPartitionModel) declare subPartition: SubPartitionModel;
}