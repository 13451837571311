import { observer }           from 'mobx-react';

import React                  from 'react';
import { layoutSidebarStore } from 'stores/index';
import '../css/LayoutSidebar.scss';

@observer
export default class LayoutSidebar extends React.Component {
	public render() {
		return layoutSidebarStore.isOpen ? (
			<div className="LayoutSidebar">
				{layoutSidebarStore.content}
			</div>
		) : null;
	}
}