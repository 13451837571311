import { computed }      from 'mobx';
import { contractTypes } from 'selects/SelectContractType';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

export default class StaffMemberContractModel extends RhPrivateApiModel {
	public _filters: ModelFiltersExtended<{ staffMember: id; 'staffMember.staff': id; }> = {};

	static get path(): string {
		return '/staff_member_contracts/{?id}';
	}

	@computed
	public get staffMemberIri(): string {
		return this.get('staffMember.@id', '');
	}

	@computed
	public get type(): string {
		return this.get('type', '');
	}

	@computed
	public get typeLabel() {
		return contractTypes.find(ct => ct.reference === this.type)?.label || '';
	}

	@computed
	public get nbHours(): number {
		return this.get('nbHours', 0);
	}
}
