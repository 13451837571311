import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('talentia_import_status')
@doc.path('/talentia_import_statuses/{?id}')
export default class TalentiaImportStatusModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': InvoiceTalentiaImportStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: InvoiceTalentiaImportStatusReference;

	public get color() {
		switch (this.reference) {
			case 'error':
				return 'red';
			case 'done':
				return 'green';
		}
	}
}