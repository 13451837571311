import GlobalContactConnectionStatusModel from 'Models/directory/GlobalContactConnectionStatusModel';
import GlobalContactStatusModel           from 'Models/directory/GlobalContactStatusModel';
import OwnerPartitionModel                from 'Models/directory/OwnerPartitionModel';
import StaffGenders                       from 'constants/StaffGenders';
import doc                                from 'decorators/doc';
import model                              from 'decorators/model';
import { Timestampable }                  from 'helpers/traits';
import { Blamable }                       from 'helpers/traits';
import { computed }                       from 'mobx';
import DirectoryPrivateApiModel           from 'modelx/models/abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Contact global')
@model.urnResource('global_contact')
@doc.path('/global_contacts/{?id}')
export default class GlobalContactModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'email': string;
		'firstName': string;
		'lastName': string;
		'ownerPartition.partitionUrn': Urn;
		'phoneNumber': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'email': string;
		'firstName': string;
		'gender': string;
		'id': string;
		'lastName': string;
		'phoneNumberBis': string;
		'phoneNumber': string;
	}> = {};

	@doc.string declare email: string;
	@doc.string declare faxNumber: string;
	@doc.string declare firstName: string;
	@doc.string declare gender: string;
	@doc.model(GlobalContactConnectionStatusModel) declare globalContactConnectionStatus: GlobalContactConnectionStatusModel;
	@doc.model(GlobalContactStatusModel) declare globalContactStatus: GlobalContactStatusModel;
	@doc.string declare lastName: string;
	@doc.model(OwnerPartitionModel) declare ownerPartition: OwnerPartitionModel;
	@doc.string declare password: string;
	@doc.string declare phoneNumber: string;
	@doc.string declare phoneNumberBis: string;
	@doc.string declare plainPassword: string;

	@computed
	public get fullName(): string {
		return `${this.firstName} ${this.lastName}`.trim();
	}
	
	@computed
	public get genderName(): string {
		return StaffGenders.find(g => g.reference === this.gender)?.label || '';
	}

	@computed
	public get civilityLabel(): string {
		return StaffGenders.find(g => g.reference === this.gender)?.civility || '';
	}
}