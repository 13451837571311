import StaffMemberModel from 'Models/rh/StaffMemberModel';

export default class AppStore {

	public staffMember = new StaffMemberModel();

	public clear() {
		this.staffMember.clear();
	}

	public load() {
		return Promise.all([
			this.staffMember.fetch(),
		]);
	}
}
