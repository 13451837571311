import lazyWithRetry from 'tools/lazyWithRetry';

const VatListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "vatListPage" */
	'../../pages/Vat/VatListPage'));

export default [
	{
		component: VatListPage,
		exact: true,
		path: '/vats',
	},
];