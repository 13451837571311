import lazyWithRetry from 'tools/lazyWithRetry';

const PartitionListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "partitionListPage" */
	'../../pages/Partition/PartitionListPage'),
);

const PartitionDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "partitionDashboardPage" */
	'../../pages/Partition/PartitionDashboardPage'));

export default [
	{
		component: PartitionListPage,
		exact: true,
		path: '/partitions',
	},
	{
		component: PartitionDashboardPage,
		exact: true,
		path: '/partitions/:partitionId',
	},
];
