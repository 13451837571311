import RightModel        from 'Models/rights/RightModel';
import doc               from 'decorators/doc';
import model             from 'decorators/model';
import resolvableUrn     from 'decorators/resolvableUrn';
import { Timestampable } from 'helpers/traits';
import { Blamable }      from 'helpers/traits';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

@model.staticLabel(`Groupe d'utilisateur`)
@model.urnResource('root_group')
@doc.path('/root_groups/{?id}')
export default class RootGroupModel extends Blamable(Timestampable(RhPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'rightUrn': Urn,
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
	}> = {};

	@doc.string declare name: string;
	@doc.string declare reference: RhRootGroupReference;

	@resolvableUrn({ attributeName: 'rightUrn' })
	declare right: RightModel;
}