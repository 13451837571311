import ClientModel                 from 'Models/directory/ClientModel';
import OwnerModel                  from 'Models/intervention/OwnerModel';
import TaskZoneGroupModel          from 'Models/intervention/TaskZoneGroupModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import nestedResolvable            from 'decorators/nestedResolvable';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Site')
@model.urnResource('task_zone')
@doc.path('/task_zones/{?id}')
export default class TaskZoneModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': boolean;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'owner': id;
		'owner.clientUrn': string;
		'owner.companyUrn': string;
		'owner.enterprisePartitionUrn': string;
		'owner.enterpriseUrn': string;
		'owner.ownerSubPartition.partitionUrn': string;
		'owner.ownerSubPartition.subPartitionUrn': string;
		'taskOperations.task': id;
		'taskOperations.task.taskTaskTags': id;
		'taskOperations.task.taskTaskTags.taskTag': id;
		'taskOperations.task.taskTaskTags.taskTag.reference': InterventionTaskTagReference;
		'taskZoneGroup': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
		'tags[$:registry:service:sales][block_status]': Urn | '*';
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
		'taskZoneGroup.name': string;
		'updatedAt': string;
		'zipCode': string;
	}> = {};

	@doc.string declare address: string;
	@doc.string declare addressBis: string;
	@doc.boolean declare archived: boolean;
	@doc.string declare city: string;
	@doc.modelUrn(ClientModel) declare client: ClientModel;
	@doc.string declare country: string;
	@doc.string declare email: string;
	@doc.string declare name: string;
	@doc.string declare note: string;
	@doc.model(OwnerModel) declare owner: OwnerModel;
	@doc.string declare phone: string;
	@doc.model(TaskZoneGroupModel) declare taskZoneGroup: TaskZoneGroupModel;
	@doc.string declare timeZone: string;
	@doc.string declare zipCode: string;

	@computed
	public get fullAddress(): string {
		return [this.address, this.addressBis, this.zipCode, this.city.toUpperCase()].filter(v => v).join(' ');
	}

	@computed
	public get phoneNumber(): string {
		return this.get('phone', '');
	}

	@nestedResolvable()
	public get enterprise() {
		return this.owner.enterprise;
	}

	@nestedResolvable()
	public get enterprisePartition() {
		return this.owner.enterprisePartition;
	}

	@nestedResolvable()
	public get company() {
		return this.owner.company;
	}

	public get backofficePath(): string {
		const ownerSubPartition = this.owner.ownerSubPartition;
		return ownerSubPartition.partition.url
			+ '/sub_partitions/'
			+ ownerSubPartition.getId('subPartition')
			+ '/task_zones/'
			+ this.id;
	}
}