import lazyWithRetry from 'tools/lazyWithRetry';

const ActivityTypeListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "activityTypeListPage" */
	'../../pages/ActivityType/ActivityTypeListPage'));

export default [
	{
		component: ActivityTypeListPage,
		exact: true,
		path: '/activity_types',
	},
];