import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoice_type')
export default class InvoiceTypeModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': InvoiceInvoiceTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	public static cacheDuration = 3600;

	static get path(): string {
		return '/invoice_types/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get reference(): InvoiceInvoiceTypeReference {
		return this.get('reference');
	}
}