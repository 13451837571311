import Form            from 'components/Form';
import { DATE_FORMAT } from 'helpers/FilterManager';
import moment          from 'moment';

export default (params, form: Form | null = null, dateFormat?: string): Filters => {
	const ignoredKeys = ['page', 'pageSize', 'orderBy', 'orderWay'];

	const filters = Object.keys(params)
		.filter(key => !ignoredKeys.includes(key))
		.reduce((obj, key) => {
			obj[key] = params[key];

			return obj;
		}, {});

	const isDateArray = (v: unknown | unknown[]) => {
		const arr = Array.isArray(v) ? v : (typeof v === 'string' ? v.split(',') : []);
		return !!arr.length && arr.every(m => moment(m, DATE_FORMAT, true).isValid());
	};

	Object.keys(filters).forEach(fieldName => {
		const field = form?.getFieldInstance(fieldName);

		if (!field || !field.props) {
			if (!Array.isArray(filters[fieldName]) && filters[fieldName].toString().includes(',')) {
				filters[fieldName] = filters[fieldName]?.split(',');
			}
		} else if (field.props.mode === 'multiple' && !Array.isArray(filters[fieldName])) {
			// Utilisation d'un tableau pour les champs "select multiple"
			filters[fieldName] = filters[fieldName]?.split(',');
		} else if (Array.isArray(field.pickerRef?.current?.props?.placeholder)) {
			// Utilisation d'un tableau moment pour les champs "rangePickers"
			const arr = Array.isArray(filters[fieldName]) ? filters[fieldName] : filters[fieldName].split(',');

			filters[fieldName] = arr.map(d => moment(d, dateFormat));
		} else if (isDateArray(filters[fieldName])) {
			if (Array.isArray(filters[fieldName])) {
				filters[fieldName] = filters[fieldName].map(d => moment(d, dateFormat));
			} else {
				filters[fieldName] = filters[fieldName].split(',').map(d => moment(d, dateFormat));
			}
		}
	});

	return filters;
};