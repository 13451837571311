import { resolvable }       from '@mathquis/modelx-resolvables';
import EnterpriseModel      from 'Models/directory/EnterpriseModel';
import { urn }              from 'helpers/ModelDictionary';
import { Blamable }         from 'helpers/traits';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'enterprise_config')
export default class EnterpriseConfigModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	static get path(): string {
		return '/enterprise_configs/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'enterpriseUrn': string;
	}> = {};

	public get onPurchaseOrder(): boolean {
		return this.get('onPurchaseOrder', false);
	}

	@resolvable(EnterpriseModel, { attributeName: 'enterpriseUrn' })
	declare enterprise: EnterpriseModel;
}