import lazyWithRetry from 'tools/lazyWithRetry';

const SynthesisAccessRightPage = lazyWithRetry(() => import(
	/* webpackChunkName: "SynthesisAccessRightPage" */
	'../../pages/SynthesisAccessRight/SynthesisAccessRightPage'),
);

export default [
	{
		component: SynthesisAccessRightPage,
		exact: true,
		path: '/synthesis_access_rights',
	},
];