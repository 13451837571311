import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('ComplianceReferentialType')
@model.urnResource('compliance_referential_type')
@doc.path('/compliance_referential_types/{?id}')
export default class ComplianceReferentialTypeModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': InterventionComplianceReferentialTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: InterventionComplianceReferentialTypeReference;
}