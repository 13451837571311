import { resolvable }    from '@mathquis/modelx-resolvables';
import PartitionModel    from 'Models/partition/PartitionModel';
import StaffMemberModel  from 'Models/rh/StaffMemberModel';
import StaffModel        from 'Models/rh/StaffModel';
import { urn }           from 'helpers/ModelDictionary';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

@urn('$', 'rh', 'change_staff_password')
export default class ChangeStaffPasswordModel extends RhPrivateApiModel {

	static get path(): string {
		return '/change_staff_passwords/{?id}';
	}

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	declare partition: PartitionModel;

	public get plainPassword(): string {
		return this.get('plainPassword', '');
	}

	public get sendMail(): boolean {
		return this.get('sendMail', false);
	}

	@resolvable(StaffModel, { attributeName: 'staff' })
	declare staff: StaffModel;

	@resolvable(StaffMemberModel, { attributeName: 'staffMember' })
	declare staffMember: StaffMemberModel;
}