import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Type de sortie')
@model.urnResource('vehicle_out_type')
@doc.path('/vehicle_out_types/{?id}')
export default class VehicleOutTypeModel extends VehiclePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': VehicleVehicleOutTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: VehicleVehicleOutTypeReference;
}