import lazyWithRetry from 'tools/lazyWithRetry';

const PaymentModeTypeListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "paymentModeTypeListPage" */
	'../../pages/PaymentModeType/PaymentModeTypeListPage'));

export default [
	{
		component: PaymentModeTypeListPage,
		exact: true,
		path: '/payment_mode_types',
	},
];