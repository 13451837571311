import { resolvable }        from '@mathquis/modelx-resolvables';
import ContextTypeModel      from 'Models/rights/ContextTypeModel';
import { urn }               from 'helpers/ModelDictionary';
import { Blamable }          from 'helpers/traits';
import { Timestampable }     from 'helpers/traits';
import RightsPrivateApiModel from '../../abstracts/RightsPrivateApiModel';

@urn('$', 'rights', 'right')
export default class RightModel extends Blamable(Timestampable(RightsPrivateApiModel)) {

	static get path(): string {
		return '/rights/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'contextType': id;
		'rightActionGroups': id;
		'rightActionGroups.actionGroup': id;
		'rightActionGroups.actionGroup.actionGroupActions.action': id;
		'system': string;
		'toPublish': boolean;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	public get label(): string {
		return this.get('label', '');
	}

	public get system(): boolean {
		return this.get('system', false);
	}

	@resolvable(ContextTypeModel, { attributeName: 'contextType' })
	declare contextType: ContextTypeModel;

	public get toPublish(): boolean {
		return this.get('toPublish', false);
	}
}