import SupplierCompanyModel   from 'Models/supplier/SupplierCompanyModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { EmptyModel }         from 'decorators/resolvableUrn';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.staticLabel('Boitier de géolocalisation')
@model.urnResource('geolocation')
@doc.path('/geolocations/{?id}')
export default class GeolocationModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'geolocationVehicles.vehicle': id;
		'supplierUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'number': string;
		'offer': string;
	}> = {};

	@doc.boolean declare enabled: boolean;
	@doc.string declare number: string;
	@doc.string declare offer: string;

	@model.resolvableUrn({ attributeName: 'supplierUrn' })
	public declare supplier: SupplierCompanyModel | EmptyModel;
}