import lazyWithRetry from 'tools/lazyWithRetry';

const EndYearDiscountRateListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "endYearDiscountRateListPage" */
	'../../pages/EndYearDiscountRate/EndYearDiscountRateListPage'));

export default [
	{
		component: EndYearDiscountRateListPage,
		exact: true,
		path: '/end_year_discount_rates',
	},
];