import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('Famille de mesure', false)
@model.urnResource('measure_group')
@doc.path('/measure_groups/{?id}')
export default class MeasureGroupModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.boolean declare archived: boolean;
	@doc.string declare label: string;
	@doc.string declare reference: InterventionMeasureGroupReference;
}