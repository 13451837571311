import _get        from 'lodash/get';
import AppConfig   from '../appConfig';
import FrontConfig from '../frontConfig';

const appConfig: AppConfig = window['APP_CONFIG'] || AppConfig || {};
const frontConfig: FrontConfig = window['FRONT_CONFIG'] || FrontConfig || {};

const get = <KeyName extends keyof Config>(key: KeyName): Config[KeyName] => {
	const config = {
		...appConfig,
		...frontConfig,
		...{
			PARTITION_URN: '$:partition:partition:7',
			TITLE: 'AURA',
		},
	};

	const value: Config[KeyName] = _get(config, key);

	if (typeof value === 'undefined') {
		console.warn('No configuration key defined: "%s"', key);
	}

	return value;
};

const getServiceConfig = (serviceName, key) => {
	const services = get('services') || [];
	const service = services.find(s => s.id === serviceName);

	if (!service) {
		return;
	}

	const value = _get(service, 'configurations.' + key);

	if (typeof value === 'undefined') {
		console.warn('No configuration key "%s" for service "%s"', key, serviceName);
	}

	return value;
};

export default { get, getServiceConfig };
