import ServiceModel                from 'Models/notification/ServiceModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import NotificationPrivateApiModel from '../../abstracts/NotificationPrivateApiModel';

@model.staticLabel('Channel')
@model.urnResource('channel')
@doc.path('/stonecode_notification_channels/{?id}')
export default class ChannelModel extends Timestampable(NotificationPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'channelContextReferences': id;
		'channelTransports': id;
		'channelTransports.transport': id;
		'channelTransports.transport.reference': NotificationTransportReference;
		'channelTransports.transport.transportType': id;
		'channelTransports.transport.transportType.reference': NotificationTransportTypeReference;
		'deprecated': '0' | '1';
		'label': string;
		'notificationChannels': id;
		'notificationChannels.notification': id;
		'reference': NotificationChannelReference;
		'service': id;
		'service.serviceUrn': string;
		'subscriptions': id;
		'subscriptions.enabled': '0' | '1';
		'subscriptions.subscriber': id;
		'subscriptions.subscriber.resourceUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
	}> = {};

	@doc.boolean declare deprecated: boolean;
	@doc.string declare description: string;
	@doc.string declare label: string;
	@doc.string declare reference: NotificationChannelReference;
	@doc.model(ServiceModel) declare service: ServiceModel;
}
