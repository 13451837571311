import lazyWithRetry from 'tools/lazyWithRetry';

const DebitMandateToActivateListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "debitMandateToActivateListPage" */
	'../../pages/DebitMandate/DebitMandateToActivateListPage'));

const DebitMandateDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "debitMandateDashboardPage" */
	'../../pages/DebitMandate/DebitMandateDashboardPage/DebitMandateDashboardPage'));

export default [
	{
		component: DebitMandateToActivateListPage,
		exact: true,
		path: '/debit_mandates',
	},
	{
		component: DebitMandateDashboardPage,
		exact: true,
		path: '/debit_mandates/:id',
	},
];
