import jwtDecode         from 'jwt-decode';
import { computed }      from 'mobx';
import { getIdFromUrn }  from 'tools/UrnTools';
import LocalStorageModel from './abstracts/LocalStorageModel';

export default class SessionModel extends LocalStorageModel {
	static get path(): string {
		return '/session';
	}

	@computed
	public get tokenData(): Record<string, string | number> {
		return jwtDecode(this.get('token')) || {};
	}

	@computed
	public get token(): string {
		return this.get('token', '');
	}

	@computed
	public get refreshToken(): string {
		return this.get('refreshToken', '');
	}

	@computed
	public get exp(): number {
		return this.tokenData.exp as number;
	}

	@computed
	public get ownerId(): number {
		return parseInt(getIdFromUrn(this.tokenData['owner.urn'] as string));
	}

	@computed
	public get isExpired(): boolean {
		return (this.exp || 0) < Math.ceil(new Date().valueOf() / 1000);
	}
}
