import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Type de commande')
@model.urnResource('order_type')
@doc.path('/order_types/{?id}')
export default class OrderTypeModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'reference': SupplierOrderTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
	}> = {};

	@doc.boolean declare archived: boolean;
	@doc.string declare label: string;
	@doc.string declare reference: SupplierOrderTypeReference;
}
