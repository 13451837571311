import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import PartitionPrivateApiModel from 'modelx/models/abstracts/PartitionPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('sub_partition_status')
@doc.path('/sub_partition_statuses/{?id}')
export default class SubPartitionStatusModel extends Timestampable(PartitionPrivateApiModel) {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: PartitionSubPartitionStatusReference;

	public get color() {
		switch (this.reference) {
			case 'to_create':
				return 'yellow';
			case 'in_creation':
				return 'blue';
			case 'created':
				return 'green';
		}
	}
}