import doc                   from 'decorators/doc';
import model                 from 'decorators/model';
import { Timestampable }     from 'helpers/traits';
import RightsPrivateApiModel from 'modelx/models/abstracts/RightsPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Contexte')
@model.urnResource('context_type')
@doc.path('/context_types/{?id}')
export default class ContextTypeModel extends Timestampable(RightsPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'actionGroups': id;
		'reference': RightsContextTypeReference;
		'rights': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.number declare binaryFlag: number;
	@doc.string declare label: string;
	@doc.string declare mask: string;
	@doc.string declare reference: RightsContextTypeReference;
}