import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('maintenance_duty_status')
@doc.path('/maintenance_duty_statuses/{?id}')
export default class MaintenanceDutyStatusModel extends VehiclePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': VehicleMaintenanceDutyStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: VehicleMaintenanceDutyStatusReference;

	public get color() {
		switch (this.reference) {
			case 'to_plan':
				return 'purple';
			case 'done':
				return 'green';
			case 'cancelled':
				return 'red';
		}
	}
}