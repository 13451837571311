import CompanyAddressModel      from 'Models/directory/CompanyAddressModel';
import CompanyContactModel      from 'Models/directory/CompanyContactModel';
import EnterpriseModel          from 'Models/directory/EnterpriseModel';
import InvoicingGroupModel      from 'Models/invoice/InvoicingGroupModel';
import SupplierCompanyModel     from 'Models/supplier/SupplierCompanyModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import DirectoryPrivateApiModel from 'modelx/models/abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Établissement')
@model.urnResource('company')
@doc.path('/companies/{?id}')
export default class CompanyModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'clientPartitions': id;
		'defaultInvoicingGroupUrn': Urn;
		'enterprise': id;
		'enterprise.enterpriseGroup': id;
		'enterprise.tags[serviceUrn][tagReference]': id;
		'name': string;
		'siretNumber': string;
		'tags[serviceUrn][tagReference]': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
		'siretNumber': string;
	}> = {};

	@doc.model(EnterpriseModel) declare enterprise: EnterpriseModel;
	@doc.boolean declare headOffice: boolean;
	@doc.string declare name: string;
	@doc.string declare siretNumber: string;

	@model.reverseResolvable<SupplierCompanyModel>({ urn: '$:supplier:supplier_company', useUrn: true })
	public declare supplierCompany: SupplierCompanyModel;

	@model.reverseResolvable<CompanyAddressModel>({ urn: '$:directory:company_address' })
	public declare companyAddress: CompanyAddressModel;

	@model.reverseResolvable<CompanyContactModel>({ urn: '$:directory:company_contact' })
	public declare companyContact: CompanyContactModel;

	@model.resolvableUrn({ attributeName: 'defaultInvoicingGroupUrn' })
	declare defaultInvoicingGroup: InvoicingGroupModel;
}
