import lazyWithRetry from 'tools/lazyWithRetry';

const SampleTypeListPage = lazyWithRetry(() => import(
	'../../../pages/SampleType/SampleTypeListPage'));

export default [
	{
		component: SampleTypeListPage,
		exact: true,
		path: '/sample_types',
	},
];
