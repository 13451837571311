import Select           from 'antd/lib/select';
import { SelectProps }  from 'antd/lib/select';
import React            from 'react';

export const contractTypes = [
	{ label: 'CDD', reference: 'CDD' },
	{ label: 'CDI', reference: 'CDI' },
	{ label: 'Interim', reference: 'INTERIM' },
];

export default (props: SelectProps<never>) => (
	<Select
		optionFilterProp="children"
		placeholder="Type de contrat"
		showSearch={true}
		size="large"

		{...props}
	>
		{contractTypes.map(contractType => (
			<Select.Option key={contractType.reference} value={contractType.reference}>
				{contractType.label}
			</Select.Option>
		))}
	</Select>
);