import MaintenanceTypeModel   from 'Models/vehicle/MaintenanceTypeModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.staticLabel('Entretien récurrent')
@model.urnResource('maintenance_plan')
@doc.path('/maintenance_plans/{?id}')
export default class MaintenancePlanModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'maintenancePlanVehicles.vehicle': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'maintenanceType.label': string;
	}> = {};

	@doc.model(MaintenanceTypeModel) declare maintenanceType: MaintenanceTypeModel;
	@doc.number declare mileage: number;
	@doc.number declare period: number;

	public get formattedPeriod() {
		return this.period ? 'Tous les ' + this.period + ' mois' : undefined;
	}

	public get formattedMileage() {
		return this.mileage === null ? '' : this.mileage + ' km';
	}
}