import { urn }              from 'helpers/ModelDictionary';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type ContractIterationStatusReference =
	'being_created'
	| 'canceled'
	| 'in_validation'
	| 'validated'
	| 'sent_to_client'
	| 'validated_by_client'
	| 'pre_activated'
	| 'activated'
	| 'suspended'
	| 'closed';

@urn('$', 'sales', 'contract_iteration_status')
export default class ContractIterationStatusModel extends SalesPrivateApiModel {

	static get path(): string {
		return '/contract_iteration_statuses/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': ContractIterationStatusReference;
	}> = {};

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): ContractIterationStatusReference {
		return this.get('reference', '');
	}

	public get color() {
		switch (this.reference) {
			case 'being_created':
			case 'canceled':
			case 'in_validation':
			case 'validated':
			case 'sent_to_client':
			case 'validated_by_client':
			case 'pre_activated':
			case 'closed':
				return 'grey';
			case 'suspended':
				return 'red';
			case 'activated':
				return 'green';
		}

		return '';
	}
}