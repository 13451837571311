import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.staticLabel('Contact')
@model.urnResource('contact')
@doc.path('/contacts/{?id}')
export default class ContactModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'dateOut[after]': string | Date;
		'dateOut[before]': string | Date;
		'dateOut[strictly_after]': string | Date;
		'dateOut[strictly_before]': string | Date;
		'email': string;
		'enabled': '0' | '1';
		'firstName': string;
		'gender': id;
		'lastName': string;
		'phoneNumber': string;
		'phoneNumberBis': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'email': string;
		'firstName': string;
		'function': string;
		'gender': string;
		'id': string;
		'lastName': string;
		'phoneNumberBis': string;
		'phoneNumber': string;
	}> = {};

	@doc.boolean declare archived: boolean;
	@doc.date declare dateOut?: Moment;
	@doc.string declare email: string;
	@doc.boolean declare enabled: boolean;
	@doc.string declare faxNumber: string;
	@doc.string declare firstName: string;
	@doc.string declare function: string;
	@doc.string declare gender: string;
	@doc.string declare lastName: string;
	@doc.string declare note: string;
	@doc.string declare password: string;
	@doc.string declare phoneNumber: string;
	@doc.string declare phoneNumberBis: string;
	@doc.string declare plainPassword: string;

	public get fullName() {
		return `${this.lastName.toUpperCase()} ${this.firstName}`.trim();
	}
}