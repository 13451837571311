window['global'] ||= window;

import * as Sentry         from '@sentry/browser';
import App                 from 'projectComponents/App/App';
import ReactDOM            from 'react-dom/client';
import React               from 'react';
import ConfigProxy         from 'tools/ConfigProxy';
import { importAllModels } from 'tools/importAllModels';
import { notificationDev } from 'tools/notification';

if (ConfigProxy.get('SENTRY_DSN_HEAD_OFFICE')) {
	Sentry.init({
		beforeSend(event, hint) {
			console.error(`SENTRY SEND\n`, hint?.originalException);

			return event;
		},
		dsn: ConfigProxy.get('SENTRY_DSN_HEAD_OFFICE'),
		environment: ConfigProxy.get('APP_ENV'),
		ignoreErrors: [
			'ResizeObserver loop limit exceeded',
			'ResizeObserver loop completed with undelivered notifications.',
			/-> Network Error/,
			/-> Request failed with status code/,
		],
		integrations: [new Sentry.Replay()],
		// If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		// This sets the sample rate to be 10%. You may want this to be 100% while in development and
		// sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		tracesSampleRate: 0.2,
	});

	Sentry.setContext('app-context', {
		app: 'backoffice',
		partition: ConfigProxy.get('PARTITION_URN'),
	});
}
importAllModels().then(() => {
	ReactDOM.createRoot(document.getElementById('main') as HTMLElement).render(<App />);
});


if (__DEV__) {
	// Affichage d'une notification pour chaque erreur dans la console
	const oldError = console.error;
	console.error = function (...args) {
		notificationDev(args[0]);
		// Appeler la méthode originale pour afficher l'erreur dans la console
		oldError.apply(console, args);
	};
}
