import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from '../../../tools/ConfigProxy';
import AbstractApiModel       from './AbstractApiModel';

export const partitionLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('partition', 'api_endpoint'),
});

export default class PartitionPrivateApiModel extends AbstractApiModel {
	public static get connector() {
		return partitionLoggedApiConnector;
	}

	public static serviceName: ServiceName = 'partition';
}
