import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Condition générale', false)
@model.urnResource('term')
@doc.path('/terms/{?id}')
export default class TermModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'title': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'title': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare content: string;
	@doc.string declare title: string;
}
