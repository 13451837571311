import lazyWithRetry from 'tools/lazyWithRetry';

const BillableCreditToValidateListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "billableCreditToValidateListPage" */
	'../../pages/Billable/BillableCreditToValidateListPage'));

const BillableDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "billableDashboardPage" */
	'../../pages/Billable/BillableDashboardPage/BillableDashboardPage'));

export default [
	{
		component: BillableCreditToValidateListPage,
		exact: true,
		path: '/billables',
	},
	{
		component: BillableDashboardPage,
		exact: true,
		path: '/billables/:billableId',
	},
];
