import { action }                         from 'mobx';
import { computed }                       from 'mobx';
import { observable }                     from 'mobx';
import { makeObservable }                 from 'mobx';
import SessionModel                       from 'modelx/models/SessionModel';
import { analyticsConnector }             from 'modelx/models/abstracts/AnalyticsApiModel';
import { commentLoggedApiConnector }      from 'modelx/models/abstracts/CommentPrivateApiModel';
import { directoryLoggedApiConnector }    from 'modelx/models/abstracts/DirectoryPrivateApiModel';
import { fileLoggedApiConnector }         from 'modelx/models/abstracts/FilePrivateApiModel';
import { interventionLoggedApiConnector } from 'modelx/models/abstracts/InterventionPrivateApiModel';
import { invoiceLoggedApiConnector }      from 'modelx/models/abstracts/InvoicePrivateApiModel';
import { notificationLoggedConnector }    from 'modelx/models/abstracts/NotificationPrivateApiModel';
import { partitionLoggedApiConnector }    from 'modelx/models/abstracts/PartitionPrivateApiModel';
import { rhConnector }                    from 'modelx/models/abstracts/RhApiModel';
import { rhLoggedConnector }              from 'modelx/models/abstracts/RhPrivateApiModel';
import { rightConnector }                 from 'modelx/models/abstracts/RightsPrivateApiModel';
import { salesLoggedConnector }           from 'modelx/models/abstracts/SalesPrivateApiModel';
import { supplierLoggedApiConnector }     from 'modelx/models/abstracts/SupplierPrivateApiModel';
import { vehicleLoggedApiConnector }      from 'modelx/models/abstracts/VehiclePrivateApiModel';
import LoginCheckModel                    from 'modelx/models/public/LoginCheckModel';
import TokenRefreshModel                  from 'modelx/models/public/TokenRefreshModel';
import ConfigProxy                        from 'tools/ConfigProxy';

const loggedConnectors = [
	fileLoggedApiConnector,
	supplierLoggedApiConnector,
	commentLoggedApiConnector,
	directoryLoggedApiConnector,
	interventionLoggedApiConnector,
	invoiceLoggedApiConnector,
	partitionLoggedApiConnector,
	rhLoggedConnector,
	salesLoggedConnector,
	notificationLoggedConnector,
	rightConnector,
	vehicleLoggedApiConnector,
	analyticsConnector,
];

const publicConnectors = [rhConnector];

const connectors = [...loggedConnectors, ...publicConnectors];

export default class AuthenticationStore {
	public session: SessionModel;

	@observable
	private _isAuthenticated: boolean;

	@observable
	private _isReady: boolean;

	constructor() {
		this._isReady = false;
		this._isAuthenticated = false;

		this.session = new SessionModel();

		connectors.forEach(connector => connector.on401(this.logout));

		makeObservable(this);
	}

	public clear() {
		this.session.destroy();
		this.session.clear();
	}

	public login(username: string, password: string): Promise<void> {
		const loginCheckModel = new LoginCheckModel();

		return loginCheckModel
			.set({
				partitionUrn: ConfigProxy.get('PARTITION_URN'),
				password,
				username,
			})
			.save()
			.then(async () => {
				this.session.set({
					refreshToken: loginCheckModel.refreshToken,
					token: loginCheckModel.token,
				});

				await this.session.save();

				this.onLoginSuccess();
			});
	}

	public logout = (): void => {
		this.setIsAuthenticated(false);
	};

	public onLoginSuccess() {
		loggedConnectors.forEach(connector => {
			connector
				.setToken(this.session.token)
				.setExpiration(this.session.exp)
				.onExpired(this.refreshTokenAsync.bind(this));
		});

		this.setIsAuthenticated(true);
	}

	public refreshTokenAsync = async (): Promise<void> => {
		const refreshModel = new TokenRefreshModel();

		if (this.session.refreshToken) {
			await refreshModel.patch({ refresh_token: this.session.refreshToken });

			this.session.set({
				refreshToken: refreshModel.refreshToken,
				token: refreshModel.token,
			});

			// Sauvegarde en localstorage
			await this.session.save();

			this.onLoginSuccess();
		} else {
			this.logout();
		}
	};

	@computed
	public get isReady(): boolean {
		return this._isReady;
	}

	@action
	public setIsAuthenticated(value: boolean): void {
		this._isAuthenticated = value;
	}

	@action
	public setIsReady(value: boolean): void {
		this._isReady = value;
	}

	@computed
	public get isAuthenticated(): boolean {
		return this._isAuthenticated;
	}
}
