import lazyWithRetry from 'tools/lazyWithRetry';

const CompanyDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "companyDashboardPage" */
	'../../pages/Company/CompanyDashboardPage'));

const CompanyListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "companyListPage" */
	'../../pages/Company/CompanyListPage'));

export default [
	{
		component: CompanyListPage,
		exact: true,
		path: '/companies',
	},
	{
		component: CompanyDashboardPage,
		exact: true,
		path: '/companies/:companyId',
	},
];