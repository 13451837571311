import lazyWithRetry from 'tools/lazyWithRetry';

const FunctionFamilyListPage = lazyWithRetry(() => import(
	'../../pages/FunctionFamily/FunctionFamilyListPage'));

export default [
	{
		component: FunctionFamilyListPage,
		exact: true,
		path: '/function_families',
	},
];
