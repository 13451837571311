import AccountingAnalyticCodeModel from 'Models/invoice/AccountingAnalyticCodeModel';
import AccountingCodeModel         from 'Models/invoice/AccountingCodeModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import SupplierPrivateApiModel     from 'modelx/models/abstracts/SupplierPrivateApiModel';

@model.cacheDuration()
@model.staticLabel(`Type d'article`)
@model.urnResource('product_type')
@doc.path('/product_types/{?id}')
export default class ProductTypeModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'accountingCodeUrn': Urn;
		'archived': '0' | '1';
		'label': id;
		'orderTypes.orderType': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.modelUrn(AccountingAnalyticCodeModel) declare accountingAnalyticCode: AccountingAnalyticCodeModel;
	@doc.modelUrn(AccountingCodeModel) declare accountingCode: AccountingCodeModel;
	@doc.boolean declare archived: boolean;
	@doc.string declare label: string;
	@doc.string declare reference: SupplierProductTypeReference;
}
