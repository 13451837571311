import { ApiCollection }            from 'Collections/ApiCollection';
import ClientModel                  from 'Models/directory/ClientModel';
import CompanyModel                 from 'Models/directory/CompanyModel';
import AccountingAnalyticCodeModel  from 'Models/invoice/AccountingAnalyticCodeModel';
import AccountingCodeModel          from 'Models/invoice/AccountingCodeModel';
import BillableItemMetaModel        from 'Models/invoice/BillableItemMetaModel';
import BillableItemModel            from 'Models/invoice/BillableItemModel';
import BillableMetaModel            from 'Models/invoice/BillableMetaModel';
import BillableModel                from 'Models/invoice/BillableModel';
import BillableStatusHistoryModel   from 'Models/invoice/BillableStatusHistoryModel';
import BillableStatusModel          from 'Models/invoice/BillableStatusModel';
import BillingGroupMetaModel        from 'Models/invoice/BillingGroupMetaModel';
import BillingGroupModel            from 'Models/invoice/BillingGroupModel';
import BillingOwnerModel            from 'Models/invoice/BillingOwnerModel';
import BillingPeriodModel           from 'Models/invoice/BillingPeriodModel';
import BillingPeriodStatusModel     from 'Models/invoice/BillingPeriodStatusModel';
import InvoiceItemModel             from 'Models/invoice/InvoiceItemModel';
import InvoiceModel                 from 'Models/invoice/InvoiceModel';
import InvoiceTypeModel             from 'Models/invoice/InvoiceTypeModel';
import InvoicingGroupModel          from 'Models/invoice/InvoicingGroupModel';
import OwnerSubPartitionModel       from 'Models/invoice/OwnerSubPartitionModel';
import VatModel                     from 'Models/invoice/VatModel';
import SubPartitionModel            from 'Models/partition/SubPartitionModel';
import ContractIterationModel       from 'Models/sales/ContractIterationModel';
import ContractModel                from 'Models/sales/ContractModel';
import QuotationModel               from 'Models/sales/QuotationModel';
import { INVOICE_TYPE_REFUND }      from 'constants/InvoiceTypes';
import { INVOICE_TYPE_INVOICE }     from 'constants/InvoiceTypes';
import { computed }                 from 'mobx';
import AbstractModelXStore          from 'stores/AbstractModelXStore';
import { getUniqueListForProperty } from 'tools/CollectionHelper';
import { getIdFromUrn }             from 'tools/UrnTools';

export default class BillableDashboardStore extends AbstractModelXStore {

	public accountingAnalyticCodeCollection = new ApiCollection(AccountingAnalyticCodeModel);
	public accountingCodeCollection = new ApiCollection(AccountingCodeModel);
	public billable = new BillableModel();
	public billableItemCollection = new ApiCollection(BillableItemModel);
	public billableItemMetaCollection = new ApiCollection(BillableItemMetaModel);
	public billableMetaCollection = new ApiCollection(BillableMetaModel);
	public billableStatusCollection = new ApiCollection(BillableStatusModel);
	public billableStatusHistoryCollection = new ApiCollection(BillableStatusHistoryModel);
	public billingGroup = new BillingGroupModel();
	public billingGroupMetaCollection = new ApiCollection(BillingGroupMetaModel);
	public billingOwner = new BillingOwnerModel();
	public billingPeriodCollection = new ApiCollection(BillingPeriodModel);
	public billingPeriodStatusCollection = new ApiCollection(BillingPeriodStatusModel);
	public client = new ClientModel();
	public company = new CompanyModel();
	public contract = new ContractModel();
	public contractIteration = new ContractIterationModel();
	public invoiceCollection = new ApiCollection(InvoiceModel);

	public invoiceInvoiceCollection = this.invoiceCollection.createVirtualCollection(
		invoice => !!this.invoiceTypeInvoice && invoice.getId('invoiceType') === this.invoiceTypeInvoice.id,
	);

	public invoiceRefundCollection = this.invoiceCollection.createVirtualCollection(
		invoice => !!this.invoiceTypeRefund && invoice.getId('invoiceType') === this.invoiceTypeRefund.id,
	);

	public invoiceTypeCollection = new ApiCollection(InvoiceTypeModel);
	public invoicingGroup = new InvoicingGroupModel();
	public ownerSubPartition = new OwnerSubPartitionModel();
	public quotation = new QuotationModel();
	public refundedInvoiceCollection = new ApiCollection(InvoiceModel);
	public refundedInvoiceItemCollection = new ApiCollection(InvoiceItemModel);
	public subPartition = new SubPartitionModel;
	public vatCollection = new ApiCollection(VatModel);

	public fetchBillableData = (billableId: id) => {
		this.billable.setId(billableId);
		this.billable.fetch().then(() => {
			this.billableStatusHistoryCollection.setFilter('billable', billableId).list();

			this.billingGroup.setId(this.billable.billingGroupId);
			this.billingGroup.fetch().then(() => {
				this.invoicingGroup
					.setId(this.billingGroup.invoicingGroupId)
					.fetch();

				this.billingOwner
					.set({ id: this.billingGroup.billingOwnerId })
					.fetch()
					.then(async () => {
						await Promise.all([
							this.client
								.setId(this.billingOwner.clientId)
								.fetch(),

							this.company
								.setId(this.billingOwner.companyId)
								.fetch(),

							this.ownerSubPartition
								.setId(this.billingOwner.ownerSubPartitionId)
								.fetch()
								.then(() => {
									this.billingPeriodCollection
										.setFilter('partitionUrn', this.ownerSubPartition.partitionUrn)
										.list();

									this.subPartition
										.setId(this.ownerSubPartition.subPartitionId)
										.fetch();
								}),
						]);
					});
			});

			this.billingGroupMetaCollection.setFilters({
				billingGroup: this.billable.billingGroupId,
				reference: ['contract_iteration_urn', 'quotation_urn'],
			});
			this.billingGroupMetaCollection.list().then(() => {
				const firstBillingGroupMeta = this.billingGroupMetaCollection.first();
				if (firstBillingGroupMeta) {
					if (firstBillingGroupMeta.reference === 'contract_iteration_urn') {
						const contractIterationId = getIdFromUrn(firstBillingGroupMeta.value);
						this.contractIteration.setId(contractIterationId);
						this.contractIteration.fetch().then(() => {
							this.contract.setId(this.contractIteration.contractId);
							this.contract.fetch();
						});
					} else {
						const quotationId = getIdFromUrn(firstBillingGroupMeta.value);
						this.quotation.setId(quotationId);
						this.quotation.fetch();
					}
				}

				this.invoiceCollection.listByFromCollection(this.billingGroupMetaCollection, 'value', 'billingGroup.billingGroupMetas.value');
			});

			this.refundedInvoiceCollection.setFilter('refundedByBillables.billable', billableId);
			this.refundedInvoiceCollection.list().then(() => {
				const invoicesIds = getUniqueListForProperty(this.refundedInvoiceCollection, 'id', value => value);
				if (invoicesIds.length < 1) {
					return;
				}
				this.refundedInvoiceItemCollection.setFilter('invoiceItemGroup.invoice', invoicesIds);
				this.refundedInvoiceItemCollection.list();
			});
		});

		this.billableItemCollection.setFilter('billable', billableId);
		this.billableItemCollection.list();

		this.billableItemMetaCollection.setFilter('billableItem.billable', billableId);
		this.billableItemMetaCollection.list();

		this.billableMetaCollection.setFilter('billable', billableId);
		this.billableMetaCollection.list();

		this.invoiceTypeCollection.list({ cache: 3600 });
	};

	public initAsync = async (billableId: id) => {
		this.fetchBillableData(billableId);

		this.accountingCodeCollection.list();
		this.accountingAnalyticCodeCollection.list();

		this.billableStatusCollection.list({ cache: 3600 });
		this.billingPeriodStatusCollection.list({ cache: 3600 });
		this.vatCollection.list();
	};

	@computed
	public get currentBillingPeriod() {
		const billingPeriodStatusOpen = this.billingPeriodStatusCollection
			.find(billingPeriodStatus => billingPeriodStatus.reference === 'open');

		if (!billingPeriodStatusOpen) {
			console.warn('billingPeriodStatusOpen not found');
			return new BillingPeriodModel();
		}

		const currentBillingPeriod = this.billingPeriodCollection.find(billingPeriod => {
			return billingPeriod.billingPeriodStatusIri === billingPeriodStatusOpen.iri;
		});

		if (!currentBillingPeriod) {
			console.warn('currentBillingPeriod not found');
			return new BillingPeriodModel();
		}

		return currentBillingPeriod;
	}

	@computed
	public get invoiceType() {
		const invoiceType = this.invoiceTypeCollection.find(invoiceType => invoiceType.id === this.billable.invoiceTypeId);

		if (!invoiceType) {
			return new InvoiceTypeModel();
		}

		return invoiceType;
	}

	@computed
	public get invoiceTypeInvoice() {
		const invoiceTypeInvoice = this.invoiceTypeCollection.find(
			invoiceType => invoiceType.reference === INVOICE_TYPE_INVOICE.reference,
		);

		if (!invoiceTypeInvoice) {
			return new InvoiceTypeModel();
		}

		return invoiceTypeInvoice;
	}

	@computed
	public get invoiceTypeRefund() {
		const invoiceTypeRefund = this.invoiceTypeCollection.find(
			invoiceType => invoiceType.reference === INVOICE_TYPE_REFUND.reference,
		);

		if (!invoiceTypeRefund) {
			return new InvoiceTypeModel();
		}

		return invoiceTypeRefund;
	}

	@computed
	public get billableStatus() {
		const billableStatus = this.billableStatusCollection.find(bs => bs.id === this.billable.billableStatusId);
		if (!billableStatus) {
			return new BillableStatusModel();
		}
		return billableStatus;
	}

	@computed
	public get isRefundable() {
		const totalInvoice = this.invoiceInvoiceCollection.models.reduce((previousValue, currentValue) => previousValue + currentValue.amountExclTax, 0);
		const totalRefund = this.invoiceRefundCollection.models.reduce((previousValue, currentValue) => previousValue + currentValue.amountExclTax, 0);
		const additionalBills = this.billableItemCollection.models.reduce((previousValue, currentValue ) => previousValue + currentValue.totalPriceExclTax, 0);
		
		return totalInvoice + totalRefund + additionalBills >= 0;
	}

	// @computed
	// public get invoiceInvoiceCollection() {
	// 	const invoiceInvoiceCollection = this.invoiceCollection.createVirtualCollection(
	// 		invoice => !!this.invoiceTypeInvoice && invoice.invoiceTypeId === this.invoiceTypeInvoice.id
	// 	);
	//
	// 	return invoiceInvoiceCollection;
	// }

	// @computed
	// public get invoiceRefundCollection() {
	// 	const invoiceRefundCollection = this.invoiceCollection.createVirtualCollection(
	// 		invoice => !!this.invoiceTypeRefund && invoice.invoiceTypeId === this.invoiceTypeRefund.id
	// 	);
	//
	// 	return invoiceRefundCollection;
	// }
}
