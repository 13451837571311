import { resolvable }                       from '@mathquis/modelx-resolvables';
import ContractIterationBillingGroupModel   from 'Models/sales/ContractIterationBillingGroupModel';
import { ContractIterationStatusReference } from 'Models/sales/ContractIterationStatusModel';
import ContractIterationStatusModel         from 'Models/sales/ContractIterationStatusModel';
import ContractModel                        from 'Models/sales/ContractModel';
import doc                                  from 'decorators/doc';
import model                                from 'decorators/model';
import { Timestampable }                    from 'helpers/traits';
import { Blamable }                         from 'helpers/traits';
import { computed }                         from 'mobx';
import moment                               from 'moment';
import SalesPrivateApiModel                 from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Contrat')
@model.urnResource('contract_iteration')
@doc.path('/contract_iterations/{?id}')
export default class ContractIterationModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	static get path(): string {
		return '/contract_iterations/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'anomaliesFlags.flag[between]': string;
		'anomaliesFlags.flag[gt]': string;
		'anomaliesFlags.flag[gte]': string;
		'anomaliesFlags.flag[lt]': string;
		'anomaliesFlags.flag[lte]': string;
		'archived': string;
		'autoliquidation': string;
		'contract': id;
		'contract.number': string;
		'contract.owner.clientUrn': string;
		'contract.owner.companyUrn': string;
		'contract.owner.enterprisePartitionUrn': string;
		'contract.owner.enterpriseUrn': string;
		'contract.owner.ownerSubPartition.partitionUrn': string;
		'contract.owner.ownerSubPartition.subPartitionUrn': string;
		'contract.recurringMode': id;
		'contract.recurringMode.reference': string;
		'contractIterationBillingGroup.billingFrequencyUrn': string;
		'contractIterationItems.contractIterationItemActivities.activity': id;
		'contractIterationItems.contractIterationItemActivities.locationUrn': string;
		'contractIterationStatus': id;
		'contractIterationStatus.reference': ContractIterationStatusReference;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'curativeInfos.curativeInfoLocations.locationUrn': string;
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'exists[curativeInfos]': string;
		'onPurchaseOrder': string;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'endDate': string;
		'startDate': string;
		'totalPriceExclTax': string;
		'totalPriceInclTax': string;
	}> = {};

	public get additionalDesignation(): string {
		return this.get('additionalDesignation', '');
	}

	public get address(): string {
		return this.get('address', '');
	}

	public get addressBis(): string {
		return this.get('addressBis', '');
	}

	public get archived(): boolean {
		return this.get('archived', false);
	}

	public get autoliquidation(): boolean {
		return this.get('autoliquidation', false);
	}

	public get billedAmountOldSystem(): number {
		return this.get('billedAmountOldSystem', 0);
	}

	public get city(): string {
		return this.get('city', '');
	}

	public get clientReference(): string {
		return this.get('clientReference', '');
	}

	@computed
	public get closedAt(): Moment | undefined {
		return this.get('closedAt') ? moment(this.get('closedAt', moment())) : undefined;
	}

	public get comment(): string {
		return this.get('comment', '');
	}

	public get country(): string {
		return this.get('country', '');
	}

	public get dc4Mode(): boolean {
		return this.get('dc4Mode', false);
	}

	public get discount(): number {
		return this.get('discount', 0);
	}

	public get duration(): number {
		return this.get('duration', 0);
	}

	@computed
	public get endDate(): Moment {
		return moment(this.get('endDate', moment()));
	}

	@computed
	public get expiresAt(): Moment {
		return moment(this.get('expiresAt', moment()));
	}

	public get note(): string {
		return this.get('note', '');
	}

	public get onPurchaseOrder(): boolean {
		return this.get('onPurchaseOrder', false);
	}

	public get reassessmentRate(): number {
		return this.get('reassessmentRate', 0);
	}

	public get reassessmentTotalPriceExclTax(): number {
		return this.get('reassessmentTotalPriceExclTax', 0);
	}

	public get reassessmentTotalPriceInclTax(): number {
		return this.get('reassessmentTotalPriceInclTax', 0);
	}

	public get amendmentOfId(): id {
		return this.get('amendmentOf.id');
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate', moment()));
	}

	public get startDateString(): string {
		return this.get('startDate', '') || '';
	}

	public get endDateString(): string {
		return this.get('endDate', '') || '';
	}

	@computed
	public get suspensionEndDate(): Moment {
		return moment(this.get('suspensionEndDate', moment()));
	}

	@computed
	public get suspensionStartDate(): Moment {
		return moment(this.get('suspensionStartDate', moment()));
	}

	public get title(): string {
		return this.get('title', '');
	}

	public get totalOverchargedWhenClosed(): number {
		return this.get('totalOverchargedWhenClosed', 0);
	}

	public get totalPriceExclTax(): number {
		return this.get('totalPriceExclTax', 0);
	}

	public get totalPriceInclTax(): number {
		return this.get('totalPriceInclTax', 0);
	}

	public get zipCode(): string {
		return this.get('zipCode', '');
	}

	public get validity(): string {
		return this.get('validity', '');
	}

	@computed
	public get contractId(): id {
		return this.get('contract.id');
	}

	@computed
	public get contractIterationBillingGroupId(): id {
		return this.get('contractIterationBillingGroup.id');
	}

	@computed
	public get contractNumber(): string {
		return this.get('contract.number');
	}

	@computed
	public get contractIterationStatusId(): id {
		return this.get('contractIterationStatus.id');
	}

	@computed
	public get contractIterationStatusIri(): string {
		return this.get('contractIterationStatus.@id');
	}

	@computed
	public get contractIterationStatusUrn(): string {
		return this.get('contractIterationStatus.@urn');
	}

	@computed
	public get denomination(): string {
		return this.get('denomination');
	}

	@computed
	public get denominationBis(): string {
		return this.get('denominationBis');
	}

	@computed
	public get endDateIsPassed(): boolean {
		return moment(this.endDate).isSameOrBefore(moment());
	}

	public get constructionCostIndex(): number {
		return this.get('constructionCostIndex', 0);
	}

	public get miscellaneousIndex(): number {
		return this.get('miscellaneousIndex', 0);
	}

	public get wageIndex(): number {
		return this.get('wageIndex', 0);
	}

	@resolvable(ContractModel, { attributeName: 'contract' })
	declare contract: ContractModel;

	@resolvable(ContractIterationBillingGroupModel, { attributeName: 'contractIterationBillingGroup' })
	declare contractIterationBillingGroup: ContractIterationBillingGroupModel;

	@resolvable(ContractIterationStatusModel, { attributeName: 'contractIterationStatus', cache: true })
	declare contractIterationStatus: ContractIterationStatusModel;
}