import lazyWithRetry from 'tools/lazyWithRetry';

const AgedTrialBalanceListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "agedTrialBalanceListPage" */
	'../../pages/Invoice/AgedTrialBalanceListPage'));

const InvoiceListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "invoiceListPage" */
	'../../pages/Invoice/InvoiceListPage'));

export default [
	{
		component: AgedTrialBalanceListPage,
		exact: true,
		path: '/aged_trial_balance',
	},
	{
		component: InvoiceListPage,
		exact: true,
		path: '/invoices',
	},
];