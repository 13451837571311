import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('allocation_request_status')
@doc.path('/allocation_request_statuses/{?id}')
export default class AllocationRequestStatusModel extends SupplierPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SupplierAllocationRequestStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SupplierAllocationRequestStatusReference;

	public get color() {
		switch (this.reference) {
			case 'refused':
				return 'red';
			case 'to_process':
				return 'blue';
			case 'processed':
				return 'green';
		}
	}
}