import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Code NAF')
@model.urnResource('naf_code')
@doc.path('/naf_codes/{?id}')
export default class NafCodeModel extends DirectoryPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'code': id;
		'label': id;
	}> = {};

	@doc.string declare code: string;
	@doc.string declare label: string;
}