import FileModel                   from 'Models/file/FileModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import { IEquipmentNameProps }     from 'modelNames/intervention/EquipmentName';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('Matériel')
@model.urnResource('equipment')
@doc.path('/equipments/{?id}')
export default class EquipmentModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'label': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	@doc.boolean declare archived: boolean;
	@doc.string declare label: string;

	@model.fileResolvable('equipment_pictogram')
	public declare pictogram: FileModel;

	public renderName(props?: Omit<IEquipmentNameProps, 'model'>) {
		return super.renderName(props);
	}
}
