import Form                                     from 'components/Form';
import { ApiViolation }                         from 'components/MultiStepForm';
import { action }                               from 'mobx';
import { computed }                             from 'mobx';
import { observable }                           from 'mobx';
import React                                    from 'react';
import { flatten }                              from 'tools/flatten';
import { isLoading }                            from 'tools/modelxTools';
import notificationApiError                     from 'tools/notificationApiError';

export interface IAbstractFormProps {
	attributes?: Record<string, string>;
	onChangeIsSubmitting?: (value: boolean) => void;
}

export abstract class AbstractForm<T> extends React.Component<T & IAbstractFormProps> {
	public formRef = React.createRef<Form>();

	@observable
	private _isSubmitting = false;

	public get form() {
		return this.formRef.current;
	}

	@computed
	public get isSubmitting() {
		return this._isSubmitting;
	}

	@action
	public setSubmitting(value: boolean) {
		if (this.props.onChangeIsSubmitting) {
			this.props.onChangeIsSubmitting(value);
		}

		this._isSubmitting = value;
	}

	public get isPropsLoading(): boolean {
		return isLoading(this.props);
	}

	public submit(): void {
		this.formRef.current?.submit();
	}

	protected _handleViolations(violations: ApiViolation[]): void {
		const fields = flatten(this.formRef.current?.getFieldsValue());

		const arr = AbstractForm._cleanViolations(violations);

		const errorFormFields = arr
			.filter(v => v.propertyPath)
			.map(v => ({
				errors: [v.message],
				name: v.propertyPath || '',
				value: v.propertyPath ? fields[v.propertyPath] : undefined,
			}));

		this.form?.setFields(errorFormFields);
	}

	protected _handleViolationsFromError(err): void {
		this._handleViolations(err?.connectorError?.response?.data?.violations || []);
	}

	protected _onError(err): void {
		this.setSubmitting(false);

		notificationApiError(err);

		this._handleViolationsFromError(err);
	}

	private static _cleanViolations(violations: ApiViolation[]): ApiViolation[] {
		return violations.map(violation => {
			const v = { ...violation };

			if (v.propertyPath) {
				v.propertyPath = v.propertyPath.replace(/\[([^\]]+)]/g, '.$1');
			}

			return v;
		});
	}
}

export default AbstractForm;
