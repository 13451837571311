import { resolvable }           from '@mathquis/modelx-resolvables';
import CompanyModel             from 'Models/directory/CompanyModel';
import { urn }                  from 'helpers/ModelDictionary';
import { Blamable }             from 'helpers/traits';
import { Timestampable }        from 'helpers/traits';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@urn('$', 'directory', 'company_contact')
export default class CompanyContactModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {

	static get path(): string {
		return '/company_contacts/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'company': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	public get email(): string {
		return this.get('email', '');
	}

	public get faxNumber(): string {
		return this.get('faxNumber', '');
	}

	public get phoneNumber(): string {
		return this.get('phoneNumber', '');
	}

	@resolvable(CompanyModel, { attributeName: 'company' })
	declare company: CompanyModel;
}