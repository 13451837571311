import lazyWithRetry from 'tools/lazyWithRetry';

const MeasureGroupListPage = lazyWithRetry(() => import(
	'../../../pages/MeasureGroup/MeasureGroupListPage'));

const MeasureGroupDashboardPage = lazyWithRetry(() => import(
	'../../../pages/MeasureGroup/MeasureGroupDashboardPage'));

export default [
	{
		component: MeasureGroupListPage,
		exact: true,
		path: '/measure_groups',
	},
	{
		component: MeasureGroupDashboardPage,
		exact: true,
		path: '/measure_groups/:measureGroupId',
	},
];
