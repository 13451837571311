import lazyWithRetry from 'tools/lazyWithRetry';

const ActionGroupDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "actionGroupDashboardPage" */
	'../../pages/ActionGroup/ActionGroupDashboardPage'));

const ActionGroupListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "actionGroupListPage" */
	'../../pages/ActionGroup/ActionGroupListPage'));

export default [
	{
		component: ActionGroupListPage,
		exact: true,
		path: '/action_groups',
	},
	{
		component: ActionGroupDashboardPage,
		exact: true,
		path: '/action_groups/:actionGroupId',
	},
];