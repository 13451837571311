import lazyWithRetry from 'tools/lazyWithRetry';

const InstallationClassificationListPage = lazyWithRetry(() => import(
	'../../../pages/InstallationClassification/InstallationClassificationListPage'));

export default [
	{
		component: InstallationClassificationListPage,
		exact: true,
		path: '/installation_classifications',
	},
];
