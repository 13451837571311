import { resolvable }         from '@mathquis/modelx-resolvables';
import BillableStatusModel    from 'Models/invoice/BillableStatusModel';
import BillingGroupModel      from 'Models/invoice/BillingGroupModel';
import InvoiceTypeModel       from 'Models/invoice/InvoiceTypeModel';
import model                  from 'decorators/model';
import nestedResolvable       from 'decorators/nestedResolvable';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import moment                 from 'moment';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('Facture', false)
export default class BillableModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billableItems.billableItemMetas.reference': string;
		'billableItems.billableItemMetas.value': id;
		'billableItems.locationUrn': string;
		'billableMetas': id;
		'billableMetas.reference': string;
		'billableMetas.value': id;
		'billableStatus': id;
		'billableStatus.reference': string;
		'billingGroup': id;
		'billingGroup.billingOwner.clientUrn': string;
		'billingGroup.billingOwner.companyUrn': string;
		'billingGroup.billingOwner.invoicingOwner.enterpriseUrn': string;
		'billingGroup.invoicingGroup.invoicingOwner.enterpriseUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'invoiceableAt[after]': string;
		'invoiceableAt[before]': string;
		'invoiceableAt[strictly_after]': string;
		'invoiceableAt[strictly_before]': string;
		'refundedInvoices.invoiceRefunded': id;
		'sourceUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	static get path(): string {
		return '/billables/{?id}';
	}

	@computed
	public get billableStatusId(): id {
		return this.get('billableStatus.id');
	}

	@computed
	public get rebilling(): boolean {
		return !!this.get('rebilling');
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get billingGroupId(): id {
		return this.get('billingGroup.id');
	}

	@computed
	public get invoiceTypeId(): id {
		return this.get('invoiceType.id');
	}

	@computed
	public get amountExclTax(): number {
		return this.get('amountExclTax');
	}

	@computed
	public get invoiceableAt(): string {
		return this.get('invoiceableAt');
	}

	public _sorts: ModelSortsExtended<{
		'billableStatus.position': string;
		'id': string;
		'updatedAt': string;
	}> = {};

	@resolvable(BillableStatusModel, { attributeName: 'billableStatus' })
	declare billableStatus: BillableStatusModel;

	@resolvable(BillingGroupModel, { attributeName: 'billingGroup' })
	declare billingGroup: BillingGroupModel;

	@computed
	public get date(): Moment {
		return moment(this.get('date', moment()));
	}

	public get description(): string {
		return this.get('description', '');
	}

	@resolvable(InvoiceTypeModel, { attributeName: 'invoiceType' })
	declare invoiceType: InvoiceTypeModel;

	// @resolvable(SourceModel, { attributeName: 'sourceUrn' })
	// declare source: SourceModel;

	@nestedResolvable()
	public get partition() {
		return this.billingGroup.billingOwner.ownerSubPartition.partition;
	}

	@nestedResolvable()
	public get subPartition() {
		return this.billingGroup.billingOwner.ownerSubPartition.subPartition;
	}

	@nestedResolvable()
	public get enterprise() {
		return this.billingGroup.billingOwner.invoicingOwner.enterprise;
	}
}
