import Form                         from 'components/Form';
import moment                       from 'moment';
import { authenticationStore }      from 'stores';
import locationTools                from 'tools/locationTools';
import transformParamsToFormFilters from 'tools/transformParamsToFormFilters';

const LOCAL_STORAGE_KEY = 'LIST_FILTERS';
export const DATE_FORMAT = 'L-LT';

class FilterManager {
	public get defaultPageKey() {
		return `${location.pathname}_USER_${authenticationStore.session.ownerId}`;
	}

	public destroy() {
		localStorage.removeItem(LOCAL_STORAGE_KEY);
	}

	public getFromLocalStorage = (key = this.defaultPageKey) => this.getLocalStorage()[key] || {};

	public getFromLocation = (location = window.location) => {
		const queryString = location.search;

		const urlParams = new URLSearchParams(queryString);

		const params = {};

		const entries = Array.from(urlParams.entries());

		for (const entry of entries) {
			params[entry[0]] = entry[1];
		}

		return params;
	};

	public getFromLocationOrLocalStorage = (key = this.defaultPageKey) => {
		const urlParams = this.getFromLocation();

		// Si des paramètres sont passés dans l'url, ils ont la priorité sur le localStorage
		if (Object.keys(urlParams).length) {
			return urlParams;
		}

		return this.getFromLocalStorage(key);
	};

	public getLocalStorage = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');

	public paramsToFormFilters = (params, form: Form | null = null) => transformParamsToFormFilters(params, form, DATE_FORMAT);

	public save(paramsToSave, key = this.defaultPageKey) {
		this.saveToUrl(paramsToSave);
		this.saveLocalStorage(paramsToSave, key);
	}

	public saveLocalStorage = (paramsToSave, key = this.defaultPageKey) => {
		const params = { ...this.getLocalStorage(), [key]: this._cleanParams(paramsToSave) };

		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this._cleanParams(params)));

		return this;
	};

	public saveToUrl = paramsToSave => {
		const params = this._cleanParams(paramsToSave);

		const url = locationTools.setValues(window.location.href.split('?')[0], params);
		window.history.replaceState(null, '', url);

		return this;
	};

	private _cleanParams = paramsToSave => {
		const params = { ...paramsToSave };

		// Remove null
		Object.keys(params).forEach(key => params[key] === null && delete params[key]);

		// Remove undefined
		Object.keys(params).forEach(key => params[key] === undefined && delete params[key]);

		// Remove empty array | string
		Object.keys(params).forEach(key => {
			const param = params[key];

			if ((Array.isArray(param) || typeof param === 'string') && !param.length) {
				delete params[key];
			}
		});

		Object.keys(params).forEach(key => {
			const value = params[key];

			if (Array.isArray(value)) {
				params[key] = value.map(v => {
					if (v instanceof moment || (typeof v === 'object' && v._isAMomentObject)) {
						return (v as Moment).format(DATE_FORMAT);
					}

					return v;
				});
			}
		});

		return params;
	};
}

export default new FilterManager();