import { ApiCollection }   from 'Collections/ApiCollection';
import CompanyAddressModel from 'Models/directory/CompanyAddressModel';
import CompanyContactModel from 'Models/directory/CompanyContactModel';
import CompanyModel        from 'Models/directory/CompanyModel';
import AbstractModelXStore from 'stores/AbstractModelXStore';

export default class CompanyDashboardStore extends AbstractModelXStore {

	public company = new CompanyModel();
	public companyAddress = new CompanyAddressModel();
	public companyContact = new CompanyContactModel();

	public async initAsync(companyId: id) {
		this.clear();

		await Promise.all([
			new ApiCollection(CompanyAddressModel)
				.setFilters({ company: companyId })
				.list()
				.then(coll => this.companyAddress.set(coll.first()?.attributes)),

			new ApiCollection(CompanyContactModel)
				.setFilters({ company: companyId })
				.list()
				.then(coll => this.companyContact.set(coll.first()?.attributes)),

			this.company.set({ id: companyId }).fetch(),
		]);
	}
}