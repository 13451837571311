import lazyWithRetry from 'tools/lazyWithRetry';

const ComplianceReferentialListPage = lazyWithRetry(() => import(
	'../../../pages/ComplianceReferential/ComplianceReferentialListPage'));

const ComplianceReferentialDashboardPage = lazyWithRetry(() => import(
	'../../../pages/ComplianceReferential/ComplianceReferentialDashboardPage'));

export default [
	{
		component: ComplianceReferentialListPage,
		exact: true,
		path: '/compliance_referentials',
	},
	{
		component: ComplianceReferentialDashboardPage,
		exact: true,
		path: '/compliance_referentials/:complianceReferentialId',
	},
];
