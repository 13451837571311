import PartitionModel         from 'Models/partition/PartitionModel';
import StaffModel             from 'Models/rh/StaffModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { BlamableEmptyModel } from 'helpers/traits';
import { computed }           from 'mobx';
import RhPrivateApiModel      from '../../abstracts/RhPrivateApiModel';

@model.staticLabel('Employé')
@model.urnResource('staff_member')
@doc.path('/staff_members/{?id}')
export default class StaffMemberModel extends RhPrivateApiModel implements BlamableEmptyModel {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'dateOut[after]': string | Date;
		'dateOut[before]': string | Date;
		'dateOut[strictly_after]': string | Date;
		'dateOut[strictly_before]': string | Date;
		'enabled': '0' | '1';
		'partitionUrn': string;
		'staff': id;
		'staff.enabled': '0' | '1';
		'staffMemberContracts.type': id;
		'staffMemberRootGroups.ownerSubPartition': id;
		'staffMemberRootGroups.ownerSubPartition.partitionUrn': string;
		'staffMemberRootGroups.ownerSubPartition.subPartitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'staff.firstName': string;
		'staff.lastName': string;
	}> = {};

	@doc.boolean declare archived: boolean;
	@doc.date declare dateIn?: Moment;
	@doc.date declare dateOut?: Moment;
	@doc.string declare email: string;
	@doc.boolean declare enabled: boolean;
	@doc.boolean declare hasMutual: boolean;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.string declare phoneNumber: string;
	@doc.model(StaffModel) declare staff: StaffModel;

	@computed
	public get fullName() {
		return this.staff.fullName;
	}
}