import MeasureGroupModel           from 'Models/intervention/MeasureGroupModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('Mesure', false)
@model.urnResource('measure')
@doc.path('/measures/{?id}')
export default class MeasureModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'hasSample': '0' | '1';
		'measureGroup': id;
		'measureMeasureMethodologies.measureMeasureMethodologyInstallationTypes.installationType': id;
		'measureMeasureMethodologies.measureMeasureMethodologyInstallationTypes.installationType.installationCategory': id;
		'measureMeasureMethodologies.measureMeasureMethodologyInstallationTypes.installationType.installationCategory.reference': InterventionInstallationCategoryReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.boolean declare archived: boolean;
	@doc.boolean declare hasSample: boolean;
	@doc.string declare label: string;
	@doc.model(MeasureGroupModel) declare measureGroup: MeasureGroupModel;
	@doc.string declare reference: InterventionMeasureReference;

	public get color() {
		switch (this.archived) {
			case true:
				return 'red';
			case false:
				return 'green';
		}
	}
}