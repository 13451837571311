import PolicyModel         from 'Models/file/PolicyModel';
import ServiceModel        from 'Models/file/ServiceModel';
import doc                 from 'decorators/doc';
import model               from 'decorators/model';
import { Timestampable }   from 'helpers/traits';
import { Blamable }        from 'helpers/traits';
import FilePrivateApiModel from '../../abstracts/FilePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Type')
@model.urnResource('type')
@doc.path('/stonecode_file_types/{?id}')
export default class TypeModel extends Blamable(Timestampable(FilePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'deprecated': '0' | '1';
		'policy': id;
		'policy.reference': FilePolicyReference;
		'reference': FileTypeReference;
		'service': id;
		'service.serviceUrn': string;
		'system': '0' | '1';
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	@doc.boolean declare defaultLock: boolean;
	@doc.boolean declare deprecated: boolean;
	@doc.string declare label: string;
	@doc.number declare maxPerSource: number;
	@doc.number declare maxSize: number;
	@doc.model(PolicyModel) declare policy: PolicyModel;
	@doc.string declare reference: FileTypeReference;
	@doc.model(ServiceModel) declare service: ServiceModel;
	@doc.boolean declare system: boolean;
	@doc.string declare urnMask: string;
}
