import { Blamable }           from '../../../../helpers/traits';
import { Timestampable }      from '../../../../helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export default class BillableStatusHistoryModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	static get path(): string {
		return '/billable_status_histories/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		billable: id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public get billableId(): id {
		return this.get('billable.id');
	}

	public get billableStatusId(): id {
		return this.get('billableStatus.id');
	}

	public get note(): string {
		return this.get('note', '');
	}
}
