import PartitionModel           from 'Models/partition/PartitionModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import PartitionPrivateApiModel from 'modelx/models/abstracts/PartitionPrivateApiModel';

@model.staticLabel(`Encart d'informations juridiques`)
@model.urnResource('partition_legal_information')
@doc.path('/partition_legal_informations/{?id}')
export default class PartitionLegalInformationModel extends Blamable(Timestampable(PartitionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'partition': id;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.number declare capital: number;
	@doc.string declare commercialCourt: string;
	@doc.string declare intraCommunityVatNumber: string;
	@doc.string declare legalForm: string;
	@doc.string declare nafCode: string;
	@doc.model(PartitionModel) declare partition: PartitionModel;
	@doc.string declare rcsRegistrationCity: string;
	@doc.string declare siret: string;
}