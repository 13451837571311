/* eslint-disable @typescript-eslint/no-explicit-any */

import { FilterFilled }          from '@ant-design/icons';
import { FilterOutlined }        from '@ant-design/icons';
import Badge                     from 'antd/lib/badge';
import Button                    from 'antd/lib/button';
import { TableProps }            from 'antd/lib/table';
import { TablePaginationConfig } from 'antd/lib/table';
import ButtonEllipsis            from 'components/ButtonEllipsis';
import Form                      from 'components/Form';
import SidebarFilterWrapper      from 'components/SidebarWrapper/SidebarFilterWrapper';
import View                      from 'components/View';
import FilterManager             from 'helpers/FilterManager';
import _uniqueId                 from 'lodash/uniqueId';
import React                     from 'react';
import DefaultSearchComponent    from './DefaultSearchListComponent';
import PageListTable             from './PageListTable';
import './PageList.scss';

const defaultPage = 1;
const defaultPageSize = 20;

const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100'];

export type PageListWay = 'asc' | 'desc' | null;

export type PageListData = {
	element: HTMLElement | null;
	fetch: () => Promise<void>;
	filters: Filters;
	form: Form | null;
	orderBy: string;
	orderWay: PageListWay
	page: number;
	pageSize: number;
	params: Record<string, string>;
	previousTablePaginationConfig?: TablePaginationConfig;
	selectedRowKeys: React.ReactText[];
	submitFilters: () => Promise<void>;
}

export interface ITableProps extends TableProps<any> {
	initialSortName?: string;
	initialSortWay?: 'asc' | 'desc' | null;
	onDrop?: (dragIndex: number, dropIndex: number) => Promise<void>;
	sortable?: boolean;
}

export interface IPageListProps {
	className?: string;
	defaultFilters?: Filters;
	defaultPageSize?: number;
	defaultSidebarOpened?: boolean;
	defaultSortName?: string;
	defaultSortWay?: PageListWay;
	disableFetchOnMount?: boolean;
	disableParamsFromLocation?: boolean;
	displayCountFilters?: boolean;
	fetch: (data: PageListData) => Promise<void>;
	formDisabled?: boolean;
	headerDisabled?: boolean;
	height?: number;
	name?: string;
	onResetFiltersCallback?: () => void;
	onRowSelectedCallback?: () => void;
	paginationDisabled?: boolean;
	paginationSimple?: boolean;
	renderSearchComponent?: (data: PageListData) => React.ReactNode;
	renderSidebarComponent?: (data: PageListData) => React.ReactNode;
	renderSubTopComponent?: (data: PageListData) => React.ReactNode;
	renderTopComponent?: (data: PageListData) => React.ReactNode;
	renderTopLeftComponent?: (data: PageListData) => React.ReactNode;
	renderTopListComponent?: (data: PageListData) => React.ReactNode;
	renderTopRightComponent?: (data: PageListData, list: PageList) => React.ReactNode;
	requesterMode?: ('always' | 'default') | boolean;
	rowSelectionEnabled?: boolean;
	searchDisabled?: boolean;
	sizeChangerHidden?: boolean;
	tableProps: ITableProps;
	total: number;
}

export interface PageListState {
	isTableReadyToDisplay: boolean;
	scrollY?: number;
	selectedRowKeys: React.ReactText[];
	suggestToSetFilters: boolean;
}

export default class PageList extends React.Component<IPageListProps, PageListState> {
	public formRef = React.createRef<Form>();
	public orderBy = '';
	public orderWay: PageListWay = 'asc';
	public pageSize = defaultPageSize;

	private _currentTablePaginationConfig: TablePaginationConfig = {};
	private _id = `PageList_${_uniqueId()}`;
	private _page = defaultPage;
	private _sidebarRef = React.createRef<SidebarFilterWrapper>();
	private _tableContainerRef = React.createRef<HTMLDivElement>();

	public constructor(props: IPageListProps) {
		super(props);

		this.pageSize = props.defaultPageSize || defaultPageSize;

		this.state = {
			isTableReadyToDisplay: false,
			scrollY: undefined,
			selectedRowKeys: [],
			suggestToSetFilters: false,
		};
	}

	public componentDidMount() {
		setTimeout(() => this._refreshTableHeight());

		window.addEventListener('resize', this._refreshTableHeight);

		this._initialize(() => {
			if (!this.props.disableFetchOnMount && !this.state.suggestToSetFilters) {
				return this.fetch();
			}
		});
	}

	public get data(): PageListData {
		const filters = this.form ? this.form.getFieldsValue() : {};

		return {
			element: document.getElementById(this._id),
			fetch: this.fetch,
			filters,
			form: this.form,
			orderBy: this.orderBy,
			orderWay: this.orderWay,
			page: this.page,
			pageSize: this.pageSize,
			params: this._getSavedParams(),
			previousTablePaginationConfig: this._currentTablePaginationConfig,
			selectedRowKeys: this.state.selectedRowKeys,
			submitFilters: this.submitFilters,
		};
	}

	public get filters(): Filters {
		return this.form ? this.form.getFieldsValue() : {};
	}

	public fetch = async () => {
		const { paginationDisabled } = this.props;

		this.setState({ suggestToSetFilters: false });

		const paramsToSave = {
			...this.filters,
			...(this.orderBy ? { orderBy: this.orderBy } : {}),
			...(this.orderWay ? { orderWay: this.orderWay } : {}),
			page: paginationDisabled ? undefined : this.page,
			pageSize: paginationDisabled ? undefined : this.pageSize,
		};

		this._saveParamsInUrlAndStorage(paramsToSave);

		await this.props.fetch(this.data);

		// Pour actualiser la hauteur quand la pagination apparaît
		this._refreshTableHeight();
	};

	public get form(): Form | null {
		return this.formRef.current;
	}

	public get storageKey(): string {
		return `${this.props.name}`;
	}

	public get page(): number {
		return this._page;
	}

	public onFormValuesChange = (changedValues: [], allValues: []) => {
		console.log(changedValues, allValues);
		return;
	};

	public render() {
		const {
			formDisabled,
			headerDisabled,
			height,
			renderSearchComponent,
			renderSidebarComponent,
			renderSubTopComponent,
			renderTopComponent,
			renderTopLeftComponent,
			renderTopListComponent,
			renderTopRightComponent,
			sizeChangerHidden,
			tableProps,
		} = this.props;

		const data = this.data;

		const classNames = [
			`PageList`,
			sizeChangerHidden ? 'size-changer-hidden' : '',
			headerDisabled ? 'header-hidden' : '',
		];

		const content = (
			<SidebarFilterWrapper
				absolute={true}
				loading={!!tableProps.loading && !this.state.suggestToSetFilters}
				onReset={this.resetFilters}
				ref={this._sidebarRef}
				renderSidebarContent={() => renderSidebarComponent && renderSidebarComponent(data)}
			>
				<div className="PageList__main">
					<div className="PageList__top">
						{renderTopComponent ? renderTopComponent(data) : (
							<View centerV gap={10} row spread wrap>
								{!!renderTopLeftComponent && (
									<View bold size={22}>
										{renderTopLeftComponent(data)}
									</View>
								)}

								<View centerV gap={8} row wrap>
									{renderSearchComponent ? renderSearchComponent(data) : (
										<DefaultSearchComponent fetch={this.submitFilters} />
									)}

									{!!renderTopRightComponent && renderTopRightComponent(data, this)}

									{!!renderSidebarComponent && this.renderSidebarButton()}
								</View>
							</View>
						)}
					</div>

					{this.state.suggestToSetFilters ? (
						<View align="center" center color="rgba(0,0,0,0.4)" marginV="10%" size={24} widthF>
							<View center gap={24} width={330}>
								<FilterFilled style={{ color: 'rgba(0,0,0,0.2)', fontSize: 60 }} />

								Veuillez sélectionner des filtres pour afficher la liste

								<Button onClick={() => {
									this._sidebarRef.current?.collapse(true);
									return this.fetch();
								}} type="primary">
									{`Ou afficher tous les résultats`.toUpperCase()}
								</Button>
							</View>
						</View>
					) : (
						<>
							{!!renderSubTopComponent && (
								<div className="PageList__subTop">
									{renderSubTopComponent(this.data)}
								</div>
							)}

							<div className="PageList__listWrapper">
								{!!renderTopListComponent && (
									<div className="PageList__topList">
										{renderTopListComponent(this.data)}
									</div>
								)}

								{this._renderList()}
							</div>
						</>
					)}
				</div>
			</SidebarFilterWrapper>
		);

		if (formDisabled) {
			return <div className={classNames.join(' ')} id={this._id} style={{ height }}>{content}</div>;
		}

		return (
			<Form
				className={classNames.join(' ')}
				id={this._id}
				onFinish={() => this.submitFilters()}
				onValuesChange={this.onFormValuesChange}
				ref={this.formRef}
				style={{ height }}
			>
				{content}
			</Form>
		);
	}

	public renderSidebarButton(onClick = this.toggleCollapseSidebar) {
		const { displayCountFilters } = this.props;

		let countFilters = 0;

		if (displayCountFilters) {
			const formFieldsValue = this.formRef.current?.getFieldsValue() || {};

			countFilters = Object.keys(formFieldsValue)
				.filter(k => k !== 'search' && typeof formFieldsValue[k] !== 'undefined')
				.filter(k => !Array.isArray(formFieldsValue[k]) || formFieldsValue[k].length) // Filtre tableaux vides
				.length;
		}

		return (
			<Badge count={countFilters} offset={[0, 9]}>
				<ButtonEllipsis actions={[{ icon: <FilterOutlined />, label: 'Filtres', onClick: onClick }]} />
			</Badge>
		);
	}

	public resetFilters = () => {
		const { onResetFiltersCallback } = this.props;

		this.form?.resetFields();

		this.fetch().catch(() => null);

		if (onResetFiltersCallback) {
			onResetFiltersCallback();
		}
	};

	public setPage(page: number) {
		this._page = page;

		return this;
	}

	public submitFilters = () => {
		this._page = 1;

		return this.fetch();
	};

	public toggleCollapseSidebar = () => {
		this._sidebarRef.current?.toggleCollapse();

		setTimeout(this._refreshTableHeight, 200);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
	protected _onChangeTable = async (p: TablePaginationConfig, _f: any, s: any) => {
		this._page = p.current || defaultPage;
		this.pageSize = p.pageSize || defaultPageSize;
		this.orderBy = s.columnKey || this.orderBy;
		this.orderWay = s.order ? (s.order === 'ascend' ? 'asc' : 'desc') : this.orderWay;

		await this.fetch();

		this._currentTablePaginationConfig = p;
	};

	protected _onRowSelected = (selectedRowKeys: React.ReactText[]): void => {
		this.setState({ selectedRowKeys }, () => this.props.onRowSelectedCallback && this.props.onRowSelectedCallback());
	};

	protected _renderList = () => {
		const { className, paginationDisabled, paginationSimple, rowSelectionEnabled, tableProps, total } = this.props;

		const { isTableReadyToDisplay, scrollY, selectedRowKeys } = this.state;

		const pagination = {
			current: this.page,
			pageSize: this.pageSize,
			pageSizeOptions: PAGE_SIZE_OPTIONS,
			showSizeChanger: true,
			showTotal: this._showTotal,
			simple: !!paginationSimple,
			total,
		};

		return (
			<div className={`PageList__list ${className}`} ref={this._tableContainerRef}>
				{isTableReadyToDisplay && (
					<PageListTable
						initialSortName={this.orderBy}
						initialSortWay={this.orderWay}
						onChange={this._onChangeTable}
						rowSelection={rowSelectionEnabled ? {
							onChange: this._onRowSelected,
							selectedRowKeys,
						} : undefined}

						{...tableProps}

						locale={{
							cancelSort: 'Cliquer pour annuler le tri',
							triggerAsc: 'Cliquez pour trier par ordre croissant',
							triggerDesc: 'Cliquez pour trier par ordre décroissant',
							...tableProps.locale,
						}}

						pagination={paginationDisabled ? false : { ...pagination, ...tableProps.pagination }}
						scroll={{ ...({ x: 'max-content', y: scrollY ? scrollY : undefined }), ...tableProps.scroll }}
					/>
				)}
			</div>
		);
	};

	protected _saveParamsInStorage(params) {
		if (this.props.name) {
			FilterManager.saveLocalStorage(params, this.storageKey);
		}
	}

	protected _saveParamsInUrl(params) {
		FilterManager.saveToUrl(params);
	}

	protected _saveParamsInUrlAndStorage = params => {
		if (this.props.name) {
			this._saveParamsInUrl(params);
			this._saveParamsInStorage(params);
		}
	};

	private _getSavedParams = (key = this.storageKey) => {
		return this.props.disableParamsFromLocation ?
			FilterManager.getFromLocalStorage(key) :
			FilterManager.getFromLocationOrLocalStorage(key);
	};

	private _initialize = (callback?: () => void) => {
		const {
			defaultFilters,
			defaultSidebarOpened,
			defaultSortName,
			defaultSortWay,
			renderSidebarComponent,
			requesterMode,
		} = this.props;

		let initialFilters = {};
		const params = this._getSavedParams();

		if (Object.keys(params).length) {
			initialFilters = FilterManager.paramsToFormFilters(params, this.form);

			if (params.page) {
				this._page = parseInt(params.page);
			}

			if (params.pageSize) {
				this.pageSize = parseInt(params.pageSize);
			}

			if (params.orderBy) {
				this.orderBy = params.orderBy;
				this.orderWay = params.orderWay as PageListWay;
			}

		} else {
			// Utilisation des valeurs par défaut

			initialFilters = defaultFilters || {};
			this.orderBy = defaultSortName || '';
			this.orderWay = defaultSortWay || 'asc';
		}

		this.form?.setFieldsValue(initialFilters);

		// Par défaut la sidebar est ouverte si des filtres sont renseignés
		if (renderSidebarComponent && typeof defaultSidebarOpened === 'undefined') {
			setTimeout(() => this._sidebarRef.current?.collapse(
				!Object.keys(initialFilters).filter(k => k !== 'search').length,
			), 1000);
		} else {
			this._sidebarRef.current?.collapse(!defaultSidebarOpened);
		}

		const suggestToSetFilters = requesterMode ? (
			(typeof requesterMode === 'boolean' || requesterMode === 'default') ?
				!Object.keys(params).filter(v => !['orderBy', 'orderWay', 'page', 'pageSize'].includes(v)).length : true
		) : false;

		if (suggestToSetFilters) {
			setTimeout(() => this._sidebarRef.current?.collapse(false), 200);
		}

		this.setState({ isTableReadyToDisplay: true, suggestToSetFilters }, callback);
	};

	private _refreshTableHeight = (): void => {
		const { paginationDisabled } = this.props;

		setTimeout(() => {
			if (this._tableContainerRef.current) {
				const headerHeight = document.querySelector(`#${this._id} .ant-table-thead`)?.clientHeight || 0;
				const paginationHeight = paginationDisabled ? 0 :
					document.querySelector(`#${this._id} .ant-pagination`)?.clientHeight || 65;
				const footerHeight = document.querySelector(`#${this._id} .ant-table-footer`)?.clientHeight || 0;
				const clientHeight = this._tableContainerRef.current.clientHeight;
				const scrollY = clientHeight - headerHeight - paginationHeight - footerHeight;

				if (this.state.scrollY !== scrollY) {
					this.setState({ scrollY });
				}
			}
		});
	};

	private _showTotal = (total: number, range: number[]): string => {
		return `Affichage de ${range[0]} à ${range[1]} de ${total} résultats`;
	};
}
