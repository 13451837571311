import ClientModel                 from 'Models/directory/ClientModel';
import ClientPartitionModel        from 'Models/directory/ClientPartitionModel';
import CompanyModel                from 'Models/directory/CompanyModel';
import EnterpriseModel             from 'Models/directory/EnterpriseModel';
import EnterprisePartitionModel    from 'Models/directory/EnterprisePartitionModel';
import OwnerSubPartitionModel      from 'Models/intervention/OwnerSubPartitionModel';
import PartitionModel              from 'Models/partition/PartitionModel';
import SubPartitionModel           from 'Models/partition/SubPartitionModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Owner')
@model.urnResource('owner')
@doc.path('/owners/{?id}')
export default class OwnerModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'clientUrn': string;
		'companyUrn': string;
		'enterprisePartitionUrn': string;
		'enterpriseUrn': string;
	}> = {};

	@doc.modelUrn(ClientModel) declare client: ClientModel;
	@doc.modelUrn(ClientPartitionModel) declare clientPartition: ClientPartitionModel;
	@doc.modelUrn(CompanyModel) declare company: CompanyModel;
	@doc.modelUrn(EnterpriseModel) declare enterprise: EnterpriseModel;
	@doc.modelUrn(EnterprisePartitionModel) declare enterprisePartition: EnterprisePartitionModel;
	@doc.model(OwnerSubPartitionModel) declare ownerSubPartition: OwnerSubPartitionModel;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.modelUrn(SubPartitionModel) declare subPartition: SubPartitionModel;
}