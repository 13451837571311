import PartitionModel    from 'Models/partition/PartitionModel';
import SubPartitionModel from 'Models/partition/SubPartitionModel';
import Avatar            from 'antd/lib/avatar/avatar';
import Popconfirm        from 'antd/lib/popconfirm';
import View              from 'components/View';
import { observer }      from 'mobx-react';
import { LinkProps }     from 'react-router-dom';
import React             from 'react';
import ConfigProxy       from 'tools/ConfigProxy';
import { getIdFromUrn }  from 'tools/UrnTools';

export default (props: LinkProps) => {
	const { children, to } = props;

	const partitionRef = React.useRef(new PartitionModel());
	const subPartitionRef = React.useRef(new SubPartitionModel());
	const [modelFound, setModelFound] = React.useState(false);

	React.useEffect(() => {
		const id = [...to.toString().matchAll(/\/sub_partitions\/([0-9]*)\//g)].map(arr => arr[1])[0];

		if (id) {
			setModelFound(true);
			subPartitionRef.current.set({ id }).fetch();
		} else {
			const backoffices = ConfigProxy.get('BACKOFFICES');
			const backoffice = backoffices.find(b => to.toString().startsWith(b.url));

			if (backoffice) {
				setModelFound(true);
				const id = getIdFromUrn(backoffice.urn);
				partitionRef.current.set({ id }).fetch();
			}
		}
	}, [to]);

	let model: SubPartitionModel | PartitionModel | undefined;

	if (modelFound) {
		if (subPartitionRef.current.id) {
			model = subPartitionRef.current;
		} else if (partitionRef.current.id) {
			model = partitionRef.current;
		}
	}

	return (
		<Popconfirm
			cancelText="Non"
			icon={null}
			okText="Oui"
			onConfirm={() => window.open(to.toString(), '_blank')}
			title={<TitleComp model={model} />}
		>
			<a href={to.toString()} onClick={e => e.preventDefault()} style={{ textDecoration: 'underline' }}>
				{children}
			</a>
		</Popconfirm>
	);
};

const TitleComp = observer((props: { model?: PartitionModel | SubPartitionModel }) => {
	const { model } = props;
	const minWidth = 350;

	let content = <span>{`Vous allez être dirigé vers un autre site, voulez vous continuer ?`}</span>;

	if (model) {
		if (model instanceof SubPartitionModel) {
			content = (
				<>
					<Avatar shape="square" size="large" src={model.partition.icon} />

					<View gap={4} row>
						{`Vous allez être dirigé sur ${model.theStaticLabel}`}
						<strong>{model.renderName()}</strong>
					</View>

					{`Voulez-vous continuer ?`}
				</>
			);
		} else {
			content = (
				<>
					<Avatar shape="square" size="large" src={model.icon} />

					<View gap={4} row>
						{`Vous allez être dirigé sur ${model.theStaticLabel}`}
						<strong>{model.renderName({ withoutIcon: true })}</strong>
					</View>

					{`Voulez-vous continuer ?`}
				</>
			);
		}
	}

	return (
		<View centerH gap={10} marginL={-22} minWidth={minWidth}>
			{content}
		</View>
	);
});