import ContextTypeModel      from 'Models/rights/ContextTypeModel';
import doc                   from 'decorators/doc';
import model                 from 'decorators/model';
import { Timestampable }     from 'helpers/traits';
import { Blamable }          from 'helpers/traits';
import RightsPrivateApiModel from '../../abstracts/RightsPrivateApiModel';

@model.staticLabel(`Droit d'accès`)
@model.urnResource('action_group')
@doc.path('/action_groups/{?id}')
export default class ActionGroupModel extends Blamable(Timestampable(RightsPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'contextType': id;
		'contextType.reference': RightsContextTypeReference;
		'actionGroupActions.action': id,
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.model(ContextTypeModel) declare contextType: ContextTypeModel;
	@doc.string declare description: string;
	@doc.string declare label: string;
	@doc.boolean declare system: boolean;
}