import lazyWithRetry from 'tools/lazyWithRetry';

const ExportPage = lazyWithRetry(
	() => import('../../pages/ExportPage/ExportPage'));

export default [
	{
		component: ExportPage,
		exact: true,
		path: '/exports/:exportId',
	}
];
