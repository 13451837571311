import lazyWithRetry from 'tools/lazyWithRetry';

const MeasureListPage = lazyWithRetry(() => import(
	'../../../pages/Measure/MeasureListPage'));

const MeasureDashboardPage = lazyWithRetry(() => import(
	'../../../pages/Measure/MeasureDashboardPage'));

export default [
	{
		component: MeasureListPage,
		exact: true,
		path: '/measures',
	},
	{
		component: MeasureDashboardPage,
		exact: true,
		path: '/measures/:measureId',
	},
];
