import { resolvable }        from '@mathquis/modelx-resolvables';
import ClientModel           from 'Models/directory/ClientModel';
import StaffMemberModel      from 'Models/rh/StaffMemberModel';
import ContractStatusModel   from 'Models/sales/ContractStatusModel';
import OwnerModel            from 'Models/sales/OwnerModel';
import RecurringModeModel    from 'Models/sales/RecurringModeModel';
import doc                   from 'decorators/doc';
import model                 from 'decorators/model';
import { Timestampable }     from 'helpers/traits';
import { Blamable }          from 'helpers/traits';
import { computed }          from 'mobx';
import moment                from 'moment';
import SalesPrivateApiModel  from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Contrat')
@model.urnResource('contract')
@doc.path('/contracts/{?id}')
export default class ContractModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'owner.clientUrn': string;
		'owner.companyUrn': string;
		'owner.enterprisePartitionUrn': string;
		'owner.enterpriseUrn': string;
		'owner.ownerSubPartition.partitionUrn': string;
		'owner.ownerSubPartition.subPartitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'contractStatus.label': string;
		'id': string;
		'number': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/contracts/{?id}';
	}

	@computed
	public get contractCategoryIri(): string {
		return this.get('contractCategory.@id');
	}

	@computed
	public get contractCategoryUrn(): string {
		return this.get('contractCategory.@urn');
	}

	@computed
	public get contractStatusIri(): string {
		return this.get('contractStatus.@id');
	}

	@computed
	public get contractStatusUrn(): string {
		return this.get('contractStatus.@urn');
	}

	@computed
	public get fullNumber(): string {
		let fullNumber = this.number;

		if (this.oldNumber) {
			fullNumber = fullNumber + ' (' + this.oldNumber + ')';
		}

		return fullNumber;
	}

	@computed
	public get number(): string {
		return this.get('number');
	}

	@computed
	public get oldNumber(): string {
		return this.get('oldNumber');
	}

	@computed
	public get ownerId(): id {
		return this.get('owner.id');
	}

	@computed
	public get ownerIri(): string {
		return this.get('owner.@id');
	}

	@computed
	public get ownerUrn(): string {
		return this.get('owner.@urn');
	}

	@computed
	public get recurringModeId(): string {
		return this.get('recurringMode.id');
	}

	@computed
	public get recurringModeIri(): string {
		return this.get('recurringMode.@id');
	}

	@computed
	public get recurringModeUrn(): string {
		return this.get('recurringMode.@urn');
	}

	@computed
	public get isRecent() {
		return moment(this.createdAt).diff(moment(), 'days') <= 30;
	}

	@resolvable(ClientModel, { attributeName: 'clientUrn' })
	declare client: ClientModel;

	@resolvable(ContractStatusModel, { attributeName: 'contractStatus' })
	declare contractStatus: ContractStatusModel;

	@resolvable(OwnerModel, { attributeName: 'owner' })
	declare owner: OwnerModel;

	@resolvable(RecurringModeModel, { attributeName: 'recurringMode' })
	declare recurringMode: RecurringModeModel;

	@resolvable(StaffMemberModel, { attributeName: 'staffMemberUrn' })
	declare staffMember: StaffMemberModel;
}
