import lazyWithRetry from 'tools/lazyWithRetry';

const AccreditationListPage = lazyWithRetry(() => import(
	'../../../pages/Accreditation/AccreditationListPage'));

const AccreditationDashboardPage = lazyWithRetry(() => import(
	'../../../pages/Accreditation/AccreditationDashboardPage'));

export default [
	{
		component: AccreditationListPage,
		exact: true,
		path: '/accreditations',
	},
	{
		component: AccreditationDashboardPage,
		exact: true,
		path: '/accreditations/:accreditationId',
	},
];
