import lazyWithRetry from 'tools/lazyWithRetry';

const NotFoundPage = lazyWithRetry(() => import(
	/* webpackChunkName: "notFoundPage" */
	'../../pages/NotFoundPage/NotFoundPage'));

export default [
	{
		component: NotFoundPage,
		path: '/*',
	},
	{
		component: NotFoundPage,
		path: '/not_found',
	},
];