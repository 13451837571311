/* eslint-disable @typescript-eslint/ban-ts-comment */

import { ConnectorResults }      from '@mathquis/modelx/';
import { AbstractApiCollection } from 'Collections/AbstractApiCollection';
import _chunk                    from 'lodash/chunk';
import _flatten                  from 'lodash/flatten';
import _uniqBy                   from 'lodash/uniqBy';
import { override }              from 'mobx';
import AbstractApiModel          from '../models/abstracts/AbstractApiModel';

export class ApiCollection<T extends AbstractApiModel> extends AbstractApiCollection<T> {

	private _chunkSize = 30;
	private _limit = 300;
	private _results?: ConnectorResults;
	private _step = 100;

	@override
	public async list(listOptions: ApiConnectorOptions<T> = {}) {
		this._results = undefined as unknown as ConnectorResults;

		const options = Object.assign({}, this._defaultOptions, listOptions);

		const idPropertyName = options.filterByIdProperty ? options.filterByIdProperty : 'id';

		const filters = this.getFilters();
		const filter = filters[idPropertyName] ?
			(Array.isArray(filters[idPropertyName]) ? filters[idPropertyName] : [filters[idPropertyName]]) as id[] : [];

		const chunkIds = (filter.length && filter.length > this._chunkSize) ? _chunk(filter, this._chunkSize) : [];

		const isFilterBy = !!chunkIds.length;
		const isFilterById = idPropertyName === 'id' && isFilterBy;
		const isFilterByOther = isFilterBy && !isFilterById;

		await super.list(this.prepareListOptions({
			...options,
			params: {
				...options.params,
				[idPropertyName]: isFilterBy ? chunkIds[0] : (filter.length ? filter : undefined),
				itemsPerPage: isFilterById ? chunkIds[0].length : (isFilterByOther ? this._limit : this._step),
				page: 1,
			},
		}));

		const total = isFilterBy ? filter.length : (this._results.res.data['hydra:totalItems'] as number || 0);
		const pageQuantity = Math.ceil(total / (isFilterBy ? this._chunkSize : this._step));

		const promises: Promise<ConnectorResults>[] = [];

		for (let page = 2; page <= pageQuantity; page++) {
			const ids = chunkIds.length ? chunkIds[page - 1] : filter;

			promises.push(
				this.model.connector.list<T>(this, this.prepareListOptions({
					...options,
					params: {
						...options.params,
						[idPropertyName]: ids.length ? ids : undefined,
						itemsPerPage: isFilterById ? ids.length : (isFilterByOther ? this._limit : this._step),
						page: isFilterBy ? 1 : page,
					},
				})),
			);
		}

		const pagedResults = await Promise.all(promises);

		const results = this._results as unknown as ConnectorResults;

		if (results) {
			results.items = [...results.items, ..._flatten(pagedResults.map(c => c.items))];
			// @ts-ignore
			results.items = _uniqBy(results.items, (m) => m.id);

			this.onSuccess(results);
			this.set(results.items.map((item) => this.createModel(item, {
				collection: this,
				loaded: true,
				transform: true,
			})));

			this.fillGlobalCache(options);
			this.setIsLoaded(true);

			console.info(`Collection ${this.model.path} loaded with ${pageQuantity} requests and ${
				this.length} results and ${filter.length} filters`);
		}

		return this;
	}

	@override
	protected onListSuccess(results: ConnectorResults) {
		this._results = results;
	}
}

export default ApiCollection;
