import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import AbstractApiModel       from './AbstractApiModel';

export const registryLoggedConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('registry', 'api_endpoint'),
});

export default class RegistryPrivateApiModel extends AbstractApiModel {
	public static get connector() {
		return registryLoggedConnector;
	}

	public static serviceName: ServiceName = 'registry';
}
