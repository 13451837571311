import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('order_part_status')
@doc.path('/order_part_statuses/{?id}')
export default class OrderPartStatusModel extends SupplierPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SupplierOrderPartStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SupplierOrderPartStatusReference;

	public get color() {
		switch (this.reference) {
			case 'in_preparation':
				return 'lightblue';
			case 'validated':
				return 'green';
			case 'to_validate':
				return 'blue';
		}
	}
}