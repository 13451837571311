import lazyWithRetry from 'tools/lazyWithRetry';

const InstallationTypeListPage = lazyWithRetry(() => import(
	'../../../pages/InstallationType/InstallationTypeListPage'));

const InstallationTypeDashboardPage = lazyWithRetry(() => import(
	'../../../pages/InstallationType/InstallationTypeDashboardPage'));

export default [
	{
		component: InstallationTypeListPage,
		exact: true,
		path: '/installation_types',
	},
	{
		component: InstallationTypeDashboardPage,
		exact: true,
		path: '/installation_types/:installationTypeId',
	},
];
