import SupplierCompanyModel   from 'Models/supplier/SupplierCompanyModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { EmptyModel }         from 'decorators/resolvableUrn';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import VehiclePrivateApiModel from 'modelx/models/abstracts/VehiclePrivateApiModel';

@model.staticLabel('Carte carburant', false)
@model.urnResource('fuel_card')
@doc.path('/fuel_cards/{?id}')
export default class FuelCardModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'fuelCardVehicles.vehicle': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.boolean declare enabled: boolean;
	@doc.string declare password: string;
	@doc.string declare reference: VehicleFuelCardReference;

	@model.resolvableUrn({ attributeName: 'supplierUrn' })
	public declare supplier: SupplierCompanyModel | EmptyModel;
}
