import StaffGenders      from 'constants/StaffGenders';
import doc               from 'decorators/doc';
import model             from 'decorators/model';
import { Timestampable } from 'helpers/traits';
import { Blamable }      from 'helpers/traits';
import { computed }      from 'mobx';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

@model.staticLabel('Utilisateur')
@model.urnResource('staff')
@doc.path('/staff/{?id}')
export default class StaffModel extends Blamable(Timestampable(RhPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'enabled': '0' | '1';
		'staffMembers': id;
		'staffMembers.partitionUrn': string;
		'staffMembers.staffMemberRootGroups.ownerSubPartition': id;
		'staffMembers.staffMemberRootGroups.ownerSubPartition.partitionUrn': string;
		'staffMembers.staffMemberRootGroups.ownerSubPartition.subPartitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'driverLicenseNumber': string;
		'email': string;
		'firstName': string;
		'gender': string;
		'id': string;
		'lastName': string;
		'nationality': string;
		'phoneNumber': string;
		'socialSecurityNumber': string;
		'updatedAt': string;
	}> = {};

	@doc.date declare birthdate?: Moment;
	@doc.string declare driverLicenseNumber: string;
	@doc.string declare email: string;
	@doc.boolean declare enabled: boolean;
	@doc.string declare firstName: string;
	@doc.string declare gender: string;
	@doc.string declare lastName: string;
	@doc.string declare nationality: string;
	@doc.string declare password: string;
	@doc.string declare phoneNumber: string;
	@doc.string declare plainPassword: string;
	@doc.string declare socialSecurityNumber: string;

	@computed
	public get civilityLabel(): string {
		return StaffGenders.find(g => g.reference === this.gender)?.civility || '';
	}

	@computed
	public get fullName(): string {
		return this.lastName.toUpperCase() + ' ' + this.firstName;
	}
}