import ApiCollection                  from 'Collections/ApiCollection';
import PagedCollection                from 'Collections/PagedCollection';
import PartitionAddressModel          from 'Models/partition/PartitionAddressModel';
import PartitionLegalInformationModel from 'Models/partition/PartitionLegalInformationModel';
import PartitionModel                 from 'Models/partition/PartitionModel';
import OwnerPartitionModel            from 'Models/sales/OwnerPartitionModel';
import AbstractModelXStore            from 'stores/AbstractModelXStore';

export default class PartitionDashboardStore extends AbstractModelXStore {
	public ownerPartition: OwnerPartitionModel | null = null;
	public partition = new PartitionModel();
	public partitionAddressCollection = new ApiCollection(PartitionAddressModel);
	public partitionLegalInformationCollection = new ApiCollection(PartitionLegalInformationModel);
	
	private _ownerPartitionCollection = new PagedCollection(OwnerPartitionModel);

	public async initAsync(id: id) {
		await this.partition.setId(id).fetch();
		await this._ownerPartitionCollection.listBy([this.partition.urn], 'partitionUrn');
		this.ownerPartition = this._ownerPartitionCollection.first();

		return Promise.all([
			this.partitionAddressCollection.listBy([id], 'partition'),
			this.partitionLegalInformationCollection.listBy([id], 'partition'),
		]);
	}
}
