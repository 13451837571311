import lazyWithRetry from 'tools/lazyWithRetry';

const ParticleClassListPage = lazyWithRetry(() => import(
	'../../../pages/ParticleClass/ParticleClassListPage'));

export default [
	{
		component: ParticleClassListPage,
		exact: true,
		path: '/particle_classes',
	},
];
