import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export default class CurrencyModel extends InvoicePrivateApiModel {
	static get path(): string {
		return '/currencies/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get reference(): string {
		return this.get('reference');
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};
}
