import StaffMemberModel        from 'Models/rh/StaffMemberModel';
import OwnerSubPartitionModel  from 'Models/supplier/OwnerSubPartitionModel';
import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import SupplierPrivateApiModel from 'modelx/models/abstracts/SupplierPrivateApiModel';

@model.staticLabel('Seuil de validation (commandes)')
@model.urnResource('validation_threshold')
@doc.path('/validation_thresholds/{?id}')
export default class ValidationThresholdModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'ownerSubPartition.subPartitionUrn': Urn;
		'staffMemberUrn': Urn;
		'threshold': number;
		'threshold[between]': string;
		'threshold[gt]': string;
		'threshold[gte]': string;
		'threshold[lt]': string;
		'threshold[lte]': string;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.model(OwnerSubPartitionModel) declare ownerSubPartition: OwnerSubPartitionModel;
	@doc.modelUrn(StaffMemberModel) declare staffMember: StaffMemberModel;
	@doc.number declare threshold: number;
}
