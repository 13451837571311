import lazyWithRetry from 'tools/lazyWithRetry';

const RootGroupListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "rootGroupListPage" */
	'../../pages/RootGroup/RootGroupListPage'));

const RootGroupDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "rootGroupDashboardPage" */
	'../../pages/RootGroup/RootGroupDashboardPage'));

export default [
	{
		component: RootGroupListPage,
		exact: true,
		path: '/root_groups',
	},
	{
		component: RootGroupDashboardPage,
		exact: true,
		path: '/root_groups/:rootGroupId',
	},
];