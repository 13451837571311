import TalentiaImportStatusModel from 'Models/invoice/TalentiaImportStatusModel';
import PartitionModel            from 'Models/partition/PartitionModel';
import doc                       from 'decorators/doc';
import model                     from 'decorators/model';
import { Timestampable }         from 'helpers/traits';
import { Blamable }              from 'helpers/traits';
import InvoicePrivateApiModel    from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.staticLabel('Synchronisation', false)
@model.urnResource('talentia_import')
@doc.path('/talentia_imports/{?id}')
export default class TalentiaImportModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'partitionUrn': string;
		'talentiaImportStatus': id;
		'talentiaImportStatus.reference': InvoiceTalentiaImportStatusReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare contentUrl: string;
	@doc.string declare file: string;
	@doc.string declare fileMimeType: string;
	@doc.string declare fileOriginalName: string;
	@doc.string declare filePath: string;
	@doc.number declare fileSize: number;
	@doc.string declare folder: string;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.model(TalentiaImportStatusModel) declare talentiaImportStatus: TalentiaImportStatusModel;
}