import { urn }              from 'helpers/ModelDictionary';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type ContratStatusReference =
	'CONTRACT_STATUS_BEING_PREPARED'
	| 'CONTRACT_STATUS_ON_GOING'
	| 'CONTRACT_STATUS_SUSPENDED'
	| 'CONTRACT_STATUS_ENDED'

@urn('$', 'sales', 'contract_status')
export default class ContractStatusModel extends SalesPrivateApiModel {

	static get path(): string {
		return '/contract_statuses/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': ContratStatusReference;
	}> = {};

	public static cacheDuration = 3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): ContratStatusReference {
		return this.get('reference', '');
	}
}